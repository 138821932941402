import React from "react";

export function TvSlo3(props: any) {
  const bgColor = props.hover ? "#2B8DFF" : "rgba(37,37,37,0.5)";
  const channelNameColor = props.hoverActive
    ? "rgb(237, 238, 239)"
    : "rgba(37, 37, 37, 0.5)";
  const desiredHeight = props.height ? props.height : "114px";
  const desiredWidth = props.width ? props.width : "107.04px";
  return (
    <>
      <svg
        id="TV_SLO_3"
        data-name="TV SLO 3"
        xmlns="http://www.w3.org/2000/svg"
        width={desiredWidth}
        height={desiredHeight}
        viewBox="0 0 107.158 114.199"
      >
        <path
          id="path4813"
          d="M12573.531-15361.229h-80.937v-114.2h80.938Z"
          transform="translate(-12492.594 15475.429)"
          fill={bgColor}
        />
        <path
          id="path4825"
          d="M-71.268-79.261a.11.11,0,0,0,.015.073v8.154a20.14,20.14,0,0,1-6.673,13.008,24.434,24.434,0,0,1-13.3,5.793H-99.6a24.9,24.9,0,0,1-12.964-5.4q-6.885-5.653-7.215-16.733h11.41c.22,4.033,1.555,7.054,3.974,9.063a13.56,13.56,0,0,0,9,3.021,12.852,12.852,0,0,0,9.342-3.52c2.434-2.346,3.637-5.573,3.637-9.679S-83.528-82.8-85.772-85.1s-5.558-3.476-9.958-3.476h-1.892v-9.958h1.892q6.049,0,9.063-3.241c2.024-2.17,3.021-5.118,3.021-8.843q0-5.83-3.358-9.063a11.537,11.537,0,0,0-8.389-3.241,11.533,11.533,0,0,0-8.33,3.065,12.614,12.614,0,0,0-3.637,8.345h-11.424q.462-9.965,7.054-15.839a23.691,23.691,0,0,1,16.337-5.866q10.185,0,16.675,6.2t6.5,16.059q0,12.077-9.958,17.012,9.437,4.158,10.911,14.68"
          transform="translate(152.19 166.431)"
          fill="#edeeef"
        />
        <path
          id="path4827"
          d="M12724.986-15171.4h13.037v-13.084h-13.037Z"
          transform="translate(-12630.865 15285.578)"
          fill={channelNameColor}
        />
        <path
          id="path4831"
          d="M0,0V9.137H2.2V5.822H12.671V3.329H2.2V0Z"
          transform="translate(94.171 84.533)"
          fill={channelNameColor}
        />
        <path
          id="path4835"
          d="M0-3.792,8.257-1.225,0,1.312V3.893L12.671-.3V-2.164L0-6.373Z"
          transform="translate(94.171 78.887)"
          fill={channelNameColor}
        />
        <path
          id="path4847"
          d="M-13.293,0a3.321,3.321,0,0,1,2.816,1.335A5.327,5.327,0,0,1-9.45,4.722a7.865,7.865,0,0,1-.381,2.625,5.238,5.238,0,0,1-1.291,2.009l-1.6-1.6a4.225,4.225,0,0,0,1.071-3.08c0-1.5-.528-2.244-1.569-2.244a1.4,1.4,0,0,0-1.071.4,2.018,2.018,0,0,0-.44,1.115l-.22,1.54a4.169,4.169,0,0,1-1.056,2.464,3.288,3.288,0,0,1-2.478.909A3.541,3.541,0,0,1-21.271,7.67a4.673,4.673,0,0,1-1.071-3.241A5.687,5.687,0,0,1-20.86.323l1.555,1.569a3.627,3.627,0,0,0-.88,2.61,2.15,2.15,0,0,0,.455,1.452,1.494,1.494,0,0,0,1.144.513,1.135,1.135,0,0,0,.909-.381,2.109,2.109,0,0,0,.5-1.173l.22-1.5A4.439,4.439,0,0,1-15.977.983,3.49,3.49,0,0,1-13.293,0"
          transform="translate(116.401 57.431)"
          fill={channelNameColor}
        />
        <path
          id="path4851"
          d="M-15.383,0V5.764H-25.854V8.257h12.671V0Z"
          transform="translate(120.02 46.837)"
          fill={channelNameColor}
        />
        <path
          id="path4863"
          d="M-7.131-.022c.337-.015.865-.015,1.584-.015s1.247,0,1.584.015S-3.215.037-2.731.1a3.649,3.649,0,0,1,1.261.4,3.815,3.815,0,0,1,.983.733A4.714,4.714,0,0,1,.906,4.657,4.677,4.677,0,0,1-.488,8.1a3.671,3.671,0,0,1-.968.733,3.641,3.641,0,0,1-1.276.381,9.389,9.389,0,0,1-1.232.117c-.308.015-.836.029-1.584.029s-1.276-.015-1.584-.029a9.389,9.389,0,0,1-1.232-.117,3.74,3.74,0,0,1-1.276-.381,3.671,3.671,0,0,1-.968-.733,4.665,4.665,0,0,1-1.379-3.446,4.7,4.7,0,0,1,1.379-3.432A4.081,4.081,0,0,1-9.624.492,3.753,3.753,0,0,1-8.363.1c.484-.059.895-.1,1.232-.117m4.048,2.6a15.212,15.212,0,0,0-2.464-.132,15.212,15.212,0,0,0-2.464.132,2.125,2.125,0,0,0-1.1.5,2.037,2.037,0,0,0-.675,1.584A2.079,2.079,0,0,0-9.111,6.27a2.117,2.117,0,0,0,1.085.484,15.835,15.835,0,0,0,2.478.117,15.835,15.835,0,0,0,2.478-.117A2.063,2.063,0,0,0-1.984,6.27a2.031,2.031,0,0,0,.675-1.613,1.99,1.99,0,0,0-.675-1.584,2.125,2.125,0,0,0-1.1-.5"
          transform="translate(106.055 36.349)"
          fill={channelNameColor}
        />
      </svg>
    </>
  );
}
