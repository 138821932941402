export enum ReceivedMessageTypes {
  TRANSCRIPT = "TRANSCRIPT",
  ERROR = "ERROR"
}

export interface ITranscriptSegment {
  word: string;
  start: number;
  end: number;
  confidence: number;
  firstInFinal?: boolean;
  spaceBefore: boolean;
}

export interface IReceived {
  messageType: ReceivedMessageTypes;
}

export enum MetadataSources {
  STT = "STT",
  PUNCTUATION = "PUNCTUATION"
}

export interface IMetadata {
  postCapitalized: boolean;
  source: MetadataSources;
}

export interface ITranscriptWord {
  startTime: number;
  endTime: number;
  confidence: number;
  text: string;
  spaceBefore: boolean;
  metadata: IMetadata;
  speakerCode?: string;
}

export interface IContent {
  id: number;
  createdAt?: number;
  createdBy?: string;
  content: ITranscriptWord[] | string;
}

export interface IReceivedTranscript extends IReceived {
  isFinal: boolean;
  decoded: number;
  transcript: IContent;
  language?: string;
}

export interface ISubtitle {
  firstRow: string | null;
  firstRowConfidence: number[];
  secondRow: string | null;
  secondRowConfidence: number[];
  startTime: number | null;
  endTime: number | null;
  numberOfWords: number;
}

export interface ILiveSubtitleData {
  finalWords: string[];
  interimWords: string[];
}

export interface ILiveSubtitle {
  firstRow: string;
  secondRow: string;
}

export interface IWord {
  text: string;
  interimIndex: number;
  belongsToFinal: boolean;
  spaceBefore: boolean;
}

export interface ILiveSubtitles {
  staticRow: IWord[];
  bufferRow: IWord[];
}

export enum Channels {
  SLO1 = 'SLO1',
  SLO2 = 'SLO2',
  SLO3 = 'SLO3',
  STREAM = 'STREAM',
}

export interface IChannel {
  label: Channels;
}