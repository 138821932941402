import React from "react";

export function ZoomInIcon(props: any) {
  const color = props.active ? "#2B8DFF" : "#edeeef";
  return (
    <>
      <svg
        id="Component_9_1"
        data-name="Component 9 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <g id="zoom_in-24px">
          <path
            id="Path_61"
            data-name="Path 61"
            d="M28,25H26.42l-.56-.54a13.02,13.02,0,1,0-1.4,1.4l.54.56V28l10,9.98L37.98,35ZM16,25a9,9,0,1,1,9-9A8.988,8.988,0,0,1,16,25Z"
            transform="translate(3 3)"
            fill={color}
          />
          <path
            id="Path_62"
            data-name="Path 62"
            d="M0,0H48V48H0Z"
            fill="rgba(0,0,0,0)"
          />
          <path
            id="Path_63"
            data-name="Path 63"
            d="M17,13H13v4H11V13H7V11h4V7h2v4h4Z"
            transform="translate(7 7)"
            fill={color}
          />
        </g>
      </svg>
    </>
  );
}
