/**
 * microphone.js
 * @date 18/2/2020
 * @version 0.4.0
 *
 * @see https://github.com/mdn/webaudio-examples/blob/master/audiocontext-states/index.html
 */
import { downmixToMono, floatTo16BitPCM, resampleAudio } from './CommonAudio';

//Aliases
(<any>window).AudioContext =
  (<any>window).AudioContext || (<any>window).webkitAudioContext;
(<any>window).OfflineAudioContext =
  (<any>window).OfflineAudioContext || (<any>window).webkitOfflineAudioContext;
AudioContext.prototype.createScriptProcessor =
  AudioContext.prototype.createScriptProcessor ||
  (<any>AudioContext.prototype).createJavaScriptNode;

export interface IMicrophoneOptions {
  chunkSize: number;
  sampleRate: number;
}

export class Microphone {
  public readonly SAMPLE_RATES: number[] = [8000, 16000, 44100];
  private audioContext: AudioContext;
  // @ts-ignore
  private stream: MediaStream;
  private sourceNode: MediaElementAudioSourceNode;
  private scriptNode: ScriptProcessorNode;
  // @ts-ignore
  private callback: ((data: ArrayBuffer) => void) | undefined;

  constructor(public options: IMicrophoneOptions) {
    // @ts-ignore
    this.audioContext = undefined;
    // @ts-ignore
    this.sourceNode = undefined;
    // @ts-ignore
    this.scriptNode = undefined;

    //Check if WebAudio library is supported by the browser
    if (
      !navigator ||
      !navigator.mediaDevices ||
      !navigator.mediaDevices.getUserMedia
    )
      if (!options)
        // throw new Error('Your browser does not support WebAudio!');
        throw new Error(
          'Error initializing AudioManager. Can not call constructor without options parameter.'
        );
    if (options.sampleRate && !this.SAMPLE_RATES.includes(options.sampleRate))
      throw new Error(
        'Error initializing Microphone object. Sample rate must be one of the following values: ' +
          this.SAMPLE_RATES
      );
    if (!options.chunkSize) throw new Error('Chunk size not defined');
  }

  private async onAudioProcess(event: AudioProcessingEvent) {
    if (!this.audioContext) {
      return;
    }

    //Down-mix to mono
    let audioData = await downmixToMono(event.inputBuffer);

    //If user requested specific sample rate then check if audio needs to be re-sampled
    if (
      this.options.sampleRate !== undefined &&
      this.audioContext.sampleRate !== this.options.sampleRate
    ) {
      audioData = await resampleAudio(audioData, this.options.sampleRate);
    }

    //Convert to 16bit little endian encoding
    let audioDataArray: ArrayBuffer = floatTo16BitPCM(
      audioData.getChannelData(0)
    );

    //Call callback given by the user on each processed audio chunk.
    if (this.callback) this.callback(audioDataArray);
  }

  registerListener(callback: (data: ArrayBuffer) => void) {
    this.callback = callback;
  }

  unregisterListener() {
    this.callback = undefined;
  }

  async start(videoTag: HTMLVideoElement): Promise<void> {
    try {
      if (this.audioContext && this.audioContext.state !== 'closed') {
        if (this.audioContext.state === 'suspended')
          await this.audioContext.resume();
        return;
      }

      if (!this.audioContext) {
        console.log('CC 1');
        this.audioContext = new ((window as any).AudioContext ||
          (window as any).webkitAudioContext)();
        this.sourceNode = this.audioContext.createMediaElementSource(videoTag);
        // Create audio processor node to access raw samples and connect it to filter output
        this.scriptNode = this.audioContext.createScriptProcessor(
          this.options.chunkSize,
          this.sourceNode.channelCount,
          1
        );
        // Connections : source --> scriptNode --> destination
        this.sourceNode.connect(this.scriptNode);
        this.scriptNode.connect(this.audioContext.destination);
        this.sourceNode.connect(this.audioContext.destination);
      }

      this.scriptNode.onaudioprocess = this.onAudioProcess.bind(this);
    } catch (exception) {
      throw new Error('No permission to use microphone! -> ' + exception);
    }
  }

  /**
   * @see https://stackoverflow.com/questions/55207924/does-the-web-audio-api-clear-out-a-source-node-after-it-finishes-playing
   * @see https://stackoverflow.com/questions/44274410/mediarecorder-stop-doesnt-clear-the-recording-icon-in-the-tab
   */
  async stop(): Promise<void> {
    if (this.audioContext && this.audioContext.state !== 'closed') {
      if (this.stream) {
        for (const track of this.stream.getTracks()) {
          track.stop();
        }
      }

      this.scriptNode.disconnect();
      await this.audioContext.close();
      // @ts-ignore
      this.audioContext = undefined;
    }
  }

  getState(): AudioContextState {
    if (!this.audioContext) return 'closed';
    return this.audioContext.state;
  }
}
