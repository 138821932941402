import React from "react";

export function TvSlo1(props: any) {
  const bgColor = props.hover ? "#2B8DFF" : "rgba(37,37,37,0.5)";
  const channelNameColor = props.hoverActive
    ? "rgb(237, 238, 239)"
    : "rgba(37, 37, 37, 0.5)";
  const desiredHeight = props.height ? props.height : "114px";
  const desiredWidth = props.width ? props.width : "107.04px";
  return (
    <>
      <svg
        id="TV_SLO_1"
        data-name="TV SLO 1"
        xmlns="http://www.w3.org/2000/svg"
        width={desiredWidth}
        height={desiredHeight}
        viewBox="0 0 107.043 113.766"
      >
        <g id="g31467" transform="translate(0 -16.813)">
          <path
            id="path2283"
            d="M12550.894-19637.451h-80.705v-113.766h80.705Z"
            transform="translate(-12470.188 19768.029)"
            fill={bgColor}
          />
          <path
            id="path2287"
            d="M-40.16-118.65H-51.712v-68.043l-15.735,13.8v-12.781l15.735-13.6H-40.16Z"
            transform="translate(98.444 249.217)"
            fill="#edeeef"
          />
          <path
            id="path2289"
            d="M12702.385-19447.871h13.1v-13.137h-13.1Z"
            transform="translate(-12608.444 19578.418)"
            fill={channelNameColor}
          />
          <path
            id="path2293"
            d="M0,0V9.169H2.208V5.835H12.722V3.349H2.208V0Z"
            transform="translate(93.989 100.782)"
            fill={channelNameColor}
          />
          <path
            id="path2297"
            d="M0-3.831,8.292-1.272,0,1.3V3.876L12.722-.35V-2.193L0-6.434Z"
            transform="translate(93.989 95.137)"
            fill={channelNameColor}
          />
          <path
            id="path2309"
            d="M-13.365,0a3.384,3.384,0,0,1,2.837,1.331A5.427,5.427,0,0,1-9.5,4.738a8.331,8.331,0,0,1-.38,2.632,5.364,5.364,0,0,1-1.3,2.033L-12.795,7.78a4.166,4.166,0,0,0,1.068-3.086c0-1.506-.512-2.252-1.565-2.252a1.391,1.391,0,0,0-1.068.395,2.182,2.182,0,0,0-.453,1.126l-.219,1.55a4.2,4.2,0,0,1-1.053,2.471,3.334,3.334,0,0,1-2.5.921,3.537,3.537,0,0,1-2.793-1.214,4.673,4.673,0,0,1-1.068-3.246A5.743,5.743,0,0,1-20.969.322L-19.4,1.9a3.631,3.631,0,0,0-.877,2.618,2.087,2.087,0,0,0,.453,1.462,1.5,1.5,0,0,0,1.155.512,1.152,1.152,0,0,0,.907-.38,2.01,2.01,0,0,0,.5-1.185l.219-1.506A4.641,4.641,0,0,1-16.07.98,3.548,3.548,0,0,1-13.365,0"
            transform="translate(116.327 73.565)"
            fill={channelNameColor}
          />
          <path
            id="path2313"
            d="M-15.474,0V5.806H-25.988V8.292h12.722V0Z"
            transform="translate(119.979 62.918)"
            fill={channelNameColor}
          />
          <path
            id="path2325"
            d="M-7.188-.022c.336-.015.863-.015,1.594-.015s1.258,0,1.594.015.746.058,1.243.132A3.529,3.529,0,0,1-1.5.49a4.578,4.578,0,0,1,.98.746A4.653,4.653,0,0,1,.869,4.673,4.673,4.673,0,0,1-.52,8.138a3.871,3.871,0,0,1-.98.746,3.98,3.98,0,0,1-1.272.366q-.768.11-1.228.132c-.307.015-.848.015-1.594.015s-1.287,0-1.594-.015S-7.9,9.323-8.417,9.25A4.194,4.194,0,0,1-9.7,8.884a4.082,4.082,0,0,1-.965-.746,4.686,4.686,0,0,1-1.4-3.466,4.666,4.666,0,0,1,1.4-3.437A4.244,4.244,0,0,1-9.689.49,3.439,3.439,0,0,1-8.431.11c.5-.073.907-.117,1.243-.132m4.065,2.6A15.386,15.386,0,0,0-5.594,2.45a15.26,15.26,0,0,0-2.471.132,2.234,2.234,0,0,0-1.111.5A2.015,2.015,0,0,0-9.85,4.673,2.035,2.035,0,0,0-9.177,6.3a2.241,2.241,0,0,0,1.1.5,18.017,18.017,0,0,0,2.486.117,18.169,18.169,0,0,0,2.486-.117,2.129,2.129,0,0,0,1.082-.5,2.046,2.046,0,0,0,.687-1.623,2.026,2.026,0,0,0-.687-1.594,2.124,2.124,0,0,0-1.1-.5"
            transform="translate(105.948 52.395)"
            fill={channelNameColor}
          />
        </g>
      </svg>
    </>
  );
}
