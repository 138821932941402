import {
  ILiveSubtitles,
  ITranscriptWord,
  IWord,
} from '../components/Main/IMain';
import { getBufferTextLength } from './LiveSubtitleLib';
import { MAX_CHARS_PER_LINE } from './generateUploadSubtitles';

const generateLiveSubtitles = (
  liveSubtitles: ILiveSubtitles,
  trContent: ITranscriptWord[],
  isFinal: boolean
) => {
  let liveSubtitlesCopy: ILiveSubtitles = JSON.parse(
    JSON.stringify(liveSubtitles)
  );

  let newBufferRow: IWord[] = [];

  let firstInterim = true;

  // Index from which we will continue to add words
  let lastIndex = -1;

  // Copy-ing and updating prev buffer
  liveSubtitlesCopy.bufferRow.forEach((word) => {
    // All final words must stay the same
    if (word.belongsToFinal) {
      newBufferRow.push(word);
    }

    // All interims must be updated, if they were received (possible that the word was removed in the interim)
    else if (trContent[word.interimIndex]) {
      if (firstInterim) {
        firstInterim = false;
      }

      newBufferRow.push({
        text: trContent[word.interimIndex].text,
        interimIndex: word.interimIndex,
        belongsToFinal: isFinal,
        spaceBefore: trContent[word.interimIndex].spaceBefore,
      });

      lastIndex = word.interimIndex;
    }
  });

  // Add 1 to get the current index which potentionally holds next word
  const nextIndex = lastIndex + 1;

  for (let i = nextIndex; i < trContent.length; i++) {
    // If it can fit into the buffer put it into the buffer
    if (
      getBufferTextLength(newBufferRow) + trContent[i].text.length <
      MAX_CHARS_PER_LINE
    ) {
      newBufferRow.push({
        text: trContent[i].text,
        interimIndex: i,
        belongsToFinal: isFinal,
        spaceBefore: trContent[i].spaceBefore,
      });
    }

    // Else move the buffer into the static row and put it into the new empty buffer
    else {
      liveSubtitlesCopy.staticRow = JSON.parse(
        JSON.stringify(newBufferRow)
      ) as IWord[];
      newBufferRow = [];
      newBufferRow.push({
        text: trContent[i].text,
        interimIndex: i,
        belongsToFinal: isFinal,
        spaceBefore: trContent[i].spaceBefore,
      });
    }
  }

  liveSubtitlesCopy.bufferRow = JSON.parse(
    JSON.stringify(newBufferRow)
  ) as IWord[];

  return liveSubtitlesCopy;
};

export default generateLiveSubtitles;
