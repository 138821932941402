import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../redux/store/IStore';

type ISaveKey = 'delay' | 'uploadInLiveMode' | 'audioChunkSize';

export default (key: ISaveKey) => {
  const userName = useSelector((state: IStore) => state.user?.username);

  const save = useCallback(
    (value: any) => {
      if (!userName) return;

      localStorage.setItem(`vls-${userName}-${key}`, JSON.stringify(value));
    },
    [userName, key]
  );

  return [save];
};
