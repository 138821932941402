import {
  IReceivedTranscript,
  ITranscriptSegment,
  ITranscriptWord,
} from "../components/Main/IMain";

const capitalize = (s: any) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export default (transcript: ITranscriptWord[], isFinal: boolean): ITranscriptSegment[] => {
  let arr: ITranscriptSegment[] = [];

  if (transcript) {

    // Round the times to 3 decimals
    for (let i = 0; i < transcript.length; i++) {
      transcript[i].startTime =
        Math.round(transcript[i].startTime * 1000) / 1000;
      transcript[i].endTime =
        Math.round(transcript[i].endTime * 1000) / 1000;
    }

    // Postprocessing
    for (let i = 0; i < transcript.length; i++) {

      // Capitalize first word
      /*
      if (i === 0) {
        transcript.msgOutClientWords[i].word = capitalize(
          transcript.msgOutClientWords[i].word
        );
      }
      */

      // Add dot to last word
      /*
      if (i === transcript.msgOutClientWords.length - 1) {
        transcript.msgOutClientWords[i].word += ".";
      }
      */

      // If current word ends after next one starts
      // Then give them both the same time (their average)
      if (
        i < transcript.length - 1 &&
        transcript[i].endTime > transcript[i + 1].startTime
      ) {
        transcript[i].endTime += transcript[i + 1].startTime;
        transcript[i].endTime /= 2;

        transcript[i + 1].startTime = transcript[i].endTime;

        // transcript[i].text += "|";
      }

      arr.push({
        word: transcript[i].text,
        start: transcript[i].startTime,
        end: transcript[i].endTime,
        confidence: transcript[i].confidence,
        firstInFinal: i === 0 && isFinal,
        spaceBefore: transcript[i].spaceBefore
      });
    }
  }

  return arr;
};
