import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';
import { Provider } from 'react-redux';
import { SnackbarProvider, SnackbarKey } from 'notistack';
import { ClearIcon } from './components/Icons/ClearIcon';

const notistackRef: any = React.createRef();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef?.current.closeSnackbar(key);
};

ReactDOM.render(
  <SnackbarProvider
    maxSnack={5}
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    // @ts-ignore
    ref={notistackRef}
    action={(key) => (
      <button onClick={onClickDismiss(key)}>
        <ClearIcon fill="white" style={{ color: 'white' }} />
      </button>
    )}
    autoHideDuration={5000}
    dense={true}
  >
    <Provider store={store}>
      <App />
    </Provider>
  </SnackbarProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
