// Aliases
window.AudioContext = window.AudioContext || window.webkitAudioContext;
window.OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
AudioContext.prototype.createScriptProcessor = AudioContext.prototype.createScriptProcessor || AudioContext.prototype.createJavaScriptNode;

const wait = async (ms) => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    })
};

function floatTo16BitPCM(input) {
    // Each 32bit (4byte) float from input is converted to one 16bit (2byte) integer.
    // Each element needs 2 bytes
    let buffer = new ArrayBuffer(input.length * 2);

    // Define view to raw buffer so we can set values as int16.
    let view = new DataView(buffer);

    for (let i = 0; i < input.length; i++) {
        // Limit input to [-1, -1]
        const s = Math.max(-1, Math.min(1, input[i]));

        // Convert float32 to int16 and force little endian
        view.setInt16(2 * i, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }

    return buffer;
}

export function processAudioFile(originalBuffer, numChannels, sampleRate, chunkSize, videoDelay, onAudioDecoded, onChunkReady) {
    return new Promise(resolve => {
        const ctx = new AudioContext();
        ctx.decodeAudioData(originalBuffer, async (decodedBuffer) => {

            // Tells that the data has been decoded (takes a while for big files)
            onAudioDecoded();

            // decodedBuffer.duration holds the duration

            const offlineCtx = new OfflineAudioContext({
                numberOfChannels: numChannels,
                sampleRate: sampleRate,
                length: decodedBuffer.duration * sampleRate
            });

            const bufferSource = offlineCtx.createBufferSource();
            bufferSource.buffer = decodedBuffer;
            bufferSource.connect(offlineCtx.destination);
            bufferSource.start();

            const decodedAndResampledBuffer = await offlineCtx.startRendering();

            const audioData = decodedAndResampledBuffer.getChannelData(0);
            for (let i = 0; i < audioData.length; i += chunkSize) {
                let sliceStart = i;
                let sliceEnd = i + chunkSize;

                if (audioData.length < sliceEnd) {
                    sliceEnd = audioData.length
                }

                const audioChunk = floatTo16BitPCM(audioData.slice(sliceStart, sliceEnd));

                // const progress = Math.floor(((i+1)/audioData.length)*100);

                const chunkDuration = (sliceEnd - sliceStart) / audioData.length * decodedBuffer.duration;

                const timeAlreadyProcessed = i / audioData.length * decodedBuffer.duration;

                // After 2 times the video delay, send the chunks in real time
                // (So the server is not overburdened)
                if(timeAlreadyProcessed > 2 * videoDelay) {
                    await wait(chunkDuration * 1000);
                }

                // Then send the chunks in real time (wait its duation and then send)
                onChunkReady(audioChunk);
            }

            onChunkReady(new ArrayBuffer(0), decodedBuffer.duration, 0);

            resolve();
        })
    });
}