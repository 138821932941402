import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDelay,
  setUploadInLiveMode,
  setAudioChunkSize,
} from '../redux/actions/actions';
import { IStore } from '../redux/store/IStore';

/** Transitions local storage variables into redux */

export default function useLocalStorage() {
  const user = useSelector((state: IStore) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user) return;

    // Set delay
    const delay = localStorage.getItem(`vls-${user.username}-delay`);
    if (delay === null) return;
    const delayNum = parseInt(JSON.parse(delay));
    dispatch(setDelay(delayNum));

    // Set uploadInLiveMode
    const uploadInLiveMode = localStorage.getItem(
      `vls-${user.username}-uploadInLiveMode`
    );
    if (uploadInLiveMode === null) return;
    dispatch(setUploadInLiveMode(JSON.parse(uploadInLiveMode)));

    // Set audioChunkSize
    const audioChunkSize = localStorage.getItem(
      `vls-${user.username}-audioChunkSize`
    );
    if (audioChunkSize === null) return;
    dispatch(setAudioChunkSize(JSON.parse(audioChunkSize)));
  }, [user, dispatch]);
}
