import {useEffect, useState} from "react";
import {ITokenResponse} from "./IWebsocket";
import {IReceived} from "../Main/IMain";
import {useSelector} from "react-redux";
import {IStore} from "../../redux/store/IStore";

// Audio location
// https://dev-on-backend.true-bar.si/api/lectures/{id}/audio.wav?access_token={jwt}

// export const websocketUrl = "ws://192.168.25.127:9090/api/device/ws";
export const websocketUrl: string = (window as any)._env_.REACT_APP_WEBSOCKET_URL;

export interface IUseWebsocket {
  openWebsocket: () => void;
  closeWebsocket: () => void;
  sendMessage: (message: any) => void;
}

export default (onMessageReceived: (message: IReceived) => void): IUseWebsocket => {
  const [ websocket, setWebsocket ] = useState<WebSocket>();

  const user = useSelector((store: IStore) => store.user);

  // Update the onMessage callback all the time so it receives the latest React state
  useEffect(() => {
    if(websocket !== undefined) {
      websocket.onmessage = onMessage;
    }
  });

  const openWebsocket = () => {
    return new Promise((async (resolve, reject) => {
      // If already open just resolve
      if(websocket !== undefined) {
        console.log("WEBSOCKET ALREADY OPENED!");
        return resolve();
      }

      // Check if user is logged in
      let token = "";
      if(user !== null && user.isAuthenticated && user.accessToken !== undefined) {
        token = user.accessToken;
      }

      else {
        return reject("User not logged in!");
      }

      const ws = new WebSocket(`${websocketUrl}?access_token=${token}`);
      ws.onmessage = onMessage;

      ws.onerror = (e) => {
        onError(e);
        reject("Websocket has thrown an error!");
      };

      ws.onclose = (e) => {
        onClose(e);
        reject("Websocket has closed itself!");
      };

      ws.onopen = () => {
        console.log("WEBSOCKET OPENED!");
        ws.onclose = onClose;
        ws.onerror = onError;
        setWebsocket(ws);
        resolve();
      };
    }));
  };

  const closeWebsocket = () => {
    return new Promise(resolve => {

      // If already closed just resolve
      if(websocket === undefined) {
        console.log("WEBSOCKET ALREADY CLOSED!");
        return resolve();
      }

      websocket.onclose = (e: CloseEvent) => {
        onClose(e);
        resolve();
      };
      websocket.close();
    });
  };

  const onClose = (e: CloseEvent) => {
    console.log("WEBSOCKET CLOSED!");
    console.log(e);
    setWebsocket(undefined);
  };

  const onMessage = (e: MessageEvent) => {
    onMessageReceived(JSON.parse(e.data) as IReceived);
  };

  const onError = (e: Event) => {
    console.log("WEBSOCKET ERROR!");
    console.log(e);
    setWebsocket(undefined);
  };

  const sendMessage = (message: ArrayBuffer) => {
    setWebsocket(websocket => {

      if(websocket !== undefined && websocket.readyState === websocket.OPEN) {
        websocket.send(message);
      }

      return websocket;
    });
  };

  return {
    openWebsocket,
    closeWebsocket,
    sendMessage
  }
}