import {IWord} from "../components/Main/IMain";

export const getBufferTextLength = (buffer: IWord[]): number => {
  let count = 0;
  buffer.forEach((word) => {
    count += word.text.length;
  });
  return count;
};

export const generateSubtitleFromBuffer = (buffer: IWord[]): string => {
  let subtitle = "";

  buffer.forEach((word, i) => {
    if(word.spaceBefore) {
      subtitle += " ";
    }

    subtitle += word.text;
  });

  return subtitle;
};