import React from "react";

export function ConfidenceIcon(props: any) {
  const color = props.active ? "#2B8DFF" : "#edeeef";
  return (
    <>
      <svg
        id="Component_11_1"
        data-name="Component 11 – 1"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <g id="spellcheck-24px">
          <path
            id="Path_35"
            data-name="Path 35"
            d="M0,0H48V48H0Z"
            fill="rgba(0,0,0,0)"
          />
          <path
            id="Path_36"
            data-name="Path 36"
            d="M22.533,29.167h4.2L16.465,3H12.728L2.46,29.167h4.2l2.25-6.038H20.243ZM10.437,19.1,14.6,7.992,18.756,19.1ZM40.9,20.29,24.643,36.574l-7.374-7.407L14.436,32,24.663,42.25,43.732,23.128Z"
            transform="translate(2.323 2.833)"
            fill={color}
          />
        </g>
      </svg>
    </>
  );
}
