import { IAction } from './IActions';
import { ISnack, IUser } from '../store/IStore';
import { IConfig, ISttStatus } from '../../components/Main/ISettings';

export const SET_CONFIG = 'SET_CONFIG';
export const SET_SNACK = 'SET_SNACK';
export const SET_MAKE_BLOCKS = 'SET_MAKE_BLOCKS';
export const SET_REFETCH_TOKENS = 'SET_REFETCH_TOKENS';
export const SET_SAVE_TO_DISK = 'SET_SAVE_TO_DISK';
export const SET_DO_DICTATION = 'SET_DO_DICTATION';
export const SET_USER = 'SET_USER';
export const SET_DELAY = 'SET_DELAY';
export const SET_UPLOAD_IN_LIVE_MODE = 'SET_UPLOAD_IN_LIVE_MODE';
export const SET_AUDIO_CHUNK_SIZE = 'SET_AUDIO_CHUNK_SIZE';
export const SET_STT_STATUS = 'SET_STT_STATUS';

export const setAudioChunkSize = (audioChunkSize: number): IAction => {
  return { type: SET_AUDIO_CHUNK_SIZE, audioChunkSize };
};

export const setUploadInLiveMode = (uploadInLiveMode: boolean): IAction => {
  return { type: SET_UPLOAD_IN_LIVE_MODE, uploadInLiveMode };
};

export const setDelay = (delay: number): IAction => {
  return { type: SET_DELAY, delay };
};

export const setDoDictation = (doDictation: boolean): IAction => {
  return { type: SET_DO_DICTATION, doDictation };
};

export const setRefetchTokens = (refetchTokens: number | null): IAction => {
  return { type: SET_REFETCH_TOKENS, refetchTokens };
};

export const setMakeBlocks = (makeBlocks: boolean): IAction => {
  return { type: SET_MAKE_BLOCKS, makeBlocks };
};

export const setSaveToDisk = (saveToDisk: boolean): IAction => {
  return { type: SET_SAVE_TO_DISK, saveToDisk };
};

export const setUser = (user: null | IUser): IAction => {
  return { type: SET_USER, user };
};

export const setConfig = (config: IConfig | null): IAction => {
  return { type: SET_CONFIG, config };
};

export const setSnack = (snack: ISnack): IAction => {
  return { type: SET_SNACK, snack };
};

export const setSttStatus = (sttStatus: ISttStatus | null): IAction => {
  return { type: SET_STT_STATUS, sttStatus };
};
