import React from "react";

export function ZoomOutIcon(props: any) {
  const color = props.active ? "#2B8DFF" : "#edeeef";
  return (
    <>
      <svg
        id="zoom_out-24px"
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 48 48"
      >
        <path
          id="Path_64"
          data-name="Path 64"
          d="M0,0H48V48H0Z"
          fill="rgba(0,0,0,0)"
        />
        <path
          id="Path_65"
          data-name="Path 65"
          d="M28,25H26.42l-.56-.54a13.02,13.02,0,1,0-1.4,1.4l.54.56V28l10,9.98L37.98,35ZM16,25a9,9,0,1,1,9-9A8.988,8.988,0,0,1,16,25ZM11,15H21v2H11Z"
          transform="translate(3 3)"
          fill={color}
        />
      </svg>
    </>
  );
}
