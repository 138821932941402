import { ISubtitle, ITranscriptSegment } from '../components/Main/IMain';

export const MAX_CHARS_PER_LINE = 42;

interface ISubtitleGenerateReturn {
  subtitleString: string;
  confidenceArray: number[];
}

const nullSubtitle = (): ISubtitle => {
  return {
    firstRow: null,
    secondRow: null,
    startTime: null,
    endTime: null,
    numberOfWords: 0,
    firstRowConfidence: [],
    secondRowConfidence: [],
  };
};

const generateUploadSubtitles = (
  transcriptSegments: ITranscriptSegment[]
): ISubtitle[] => {
  if (transcriptSegments.length === 0) {
    return [];
  }

  const words = JSON.parse(
    JSON.stringify(transcriptSegments)
  ) as ITranscriptSegment[];

  let returnSubtitles: ISubtitle[] = [];
  let returnSubtitle: ISubtitle = nullSubtitle();
  returnSubtitle.numberOfWords = 0;

  let subtitleBuffer: ITranscriptSegment[] = [];

  for (let i = 0; i < words.length; i++) {
    subtitleBuffer.push(words[i]);

    if (transcriptSegmentCharacterNumber(subtitleBuffer) > MAX_CHARS_PER_LINE) {
      subtitleBuffer.splice(subtitleBuffer.length - 1, 1);
      i--;

      returnSubtitle.numberOfWords += subtitleBuffer.length;
      if (!returnSubtitle.startTime) {
        returnSubtitle.startTime = subtitleBuffer[0].start;
      }

      if (
        !returnSubtitle.endTime ||
        subtitleBuffer[subtitleBuffer.length - 1].end > returnSubtitle.endTime
      ) {
        returnSubtitle.endTime = subtitleBuffer[subtitleBuffer.length - 1].end;
      }

      const { subtitleString, confidenceArray } =
        generateStringFromSubtitleBuffer(subtitleBuffer);

      // Put subtitle into first row
      if (!returnSubtitle.firstRow) {
        returnSubtitle.firstRow = subtitleString;
        returnSubtitle.firstRowConfidence = confidenceArray;
        subtitleBuffer = [];
      }

      // Put subtitle into second row and finish the subtitle
      else if (!returnSubtitle.secondRow) {
        returnSubtitle.secondRow = subtitleString;
        returnSubtitle.secondRowConfidence = confidenceArray;
        returnSubtitles.push(JSON.parse(JSON.stringify(returnSubtitle)));
        returnSubtitle = nullSubtitle();
        subtitleBuffer = [];
      }
    }
  }

  // If end of subtitles
  returnSubtitle.numberOfWords += subtitleBuffer.length;
  if (!returnSubtitle.startTime) {
    returnSubtitle.startTime = subtitleBuffer[0].start;
  }

  if (
    !returnSubtitle.endTime ||
    subtitleBuffer[subtitleBuffer.length - 1].end > returnSubtitle.endTime
  ) {
    returnSubtitle.endTime = subtitleBuffer[subtitleBuffer.length - 1].end;
  }

  const { subtitleString, confidenceArray } =
    generateStringFromSubtitleBuffer(subtitleBuffer);

  // Put subtitle into first row
  if (!returnSubtitle.firstRow) {
    returnSubtitle.firstRow = subtitleString;
    returnSubtitle.firstRowConfidence = confidenceArray;
  }

  // Put subtitle into second row and finish the subtitle
  else if (!returnSubtitle.secondRow) {
    returnSubtitle.secondRow = subtitleString;
    returnSubtitle.secondRowConfidence = confidenceArray;
  }

  returnSubtitles.push(returnSubtitle);

  return returnSubtitles;
};

export default generateUploadSubtitles;

export const transcriptSegmentCharacterNumber = (
  subtitleBuffer: ITranscriptSegment[]
): number => {
  let characterNumber = 0;
  subtitleBuffer.forEach((el) => {
    characterNumber += el.word.length;
  });
  return characterNumber;
};

const generateStringFromSubtitleBuffer = (
  subtitleBuffer: ITranscriptSegment[]
): ISubtitleGenerateReturn => {
  let subtitleString = '';
  let confidenceArray: number[] = [];
  subtitleBuffer.forEach((el, index) => {
    confidenceArray.push(el.confidence);
    if (el.spaceBefore) {
      subtitleString += ' ';
    }
    subtitleString += el.word;
  });
  return { subtitleString, confidenceArray };
};
