import {ITranscriptWord, MetadataSources} from "../components/Main/IMain";

export default (trContent: ITranscriptWord[]): ITranscriptWord[] => {

  let trContentCopy = JSON.parse(JSON.stringify(trContent)) as ITranscriptWord[];

  for(let i = 0; i < trContentCopy.length; i++) {

    // If you find a punctuation, then join it with previous word
    if(!trContentCopy[i].spaceBefore && i > 0) {
      trContentCopy[i-1].text += trContentCopy[i].text;
      trContentCopy[i-1].endTime = trContentCopy[i].endTime;

      trContentCopy.splice(i, 1);
      i--;
    }
  }

  return trContentCopy;
}