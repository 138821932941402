import React, { useState } from 'react';
import './main.css';

import { ILiveSubtitles, ISubtitle } from './IMain';
import { ZoomInIcon } from '../Icons/ZoomIcon';
import { ZoomOutIcon } from '../Icons/ZoomOutIcon';
import { ConfidenceIcon } from '../Icons/ConfidenceIconNew';
import { generateSubtitleFromBuffer } from '../../shared/LiveSubtitleLib';
import { FlowModes } from './Main';
import { TextAlignLeft } from '../Icons/TextAlignLeft';
import { TextAlignCenter } from '../Icons/TextAlignCenter';

interface IProps {
  flowMode: FlowModes | undefined;
  showCommands: boolean;
  setShowCommands: (value: boolean) => void;
  increaseFontSize: () => void;
  decreaseFontSize: () => void;
  toggleConfidence: () => void;
  shownSubtitle: ISubtitle | null;
  subtitleFontSize: number;
  showConfidence: boolean;
  videoIsFullScreen: boolean;
  liveSubtitles: ILiveSubtitles;
}

const Subtitles = ({
  flowMode,
  showCommands,
  setShowCommands,
  increaseFontSize,
  decreaseFontSize,
  toggleConfidence,
  shownSubtitle,
  subtitleFontSize,
  showConfidence,
  videoIsFullScreen,
  liveSubtitles,
}: IProps) => {
  const [activeIcon, setActiveIcon] = useState<string>('');
  const [isCentered, setIsCentered] = useState<boolean>(true);

  const staticRowSubtitle = generateSubtitleFromBuffer(liveSubtitles.staticRow);
  const bufferRowSubtitle = generateSubtitleFromBuffer(liveSubtitles.bufferRow);

  const toggleTextAlign = () => {
    setIsCentered(!isCentered);
  };

  return (
    <div
      onMouseEnter={() => setShowCommands(true)}
      onMouseLeave={() => setShowCommands(false)}
      className={
        videoIsFullScreen
          ? 'subtitles_container fullscreen'
          : 'subtitles_container'
      }
    >
      <div
        className={
          showCommands
            ? 'subtitles_commands_overlay show'
            : 'subtitles_commands_overlay'
        }
      >
        {showCommands && (
          <div className="commands_wrapper">
            <div
              className="command_wrapper"
              onClick={increaseFontSize}
              onMouseEnter={() => setActiveIcon('in')}
              onMouseLeave={() => setActiveIcon('')}
            >
              <ZoomInIcon active={activeIcon === 'in'} />{' '}
              <span className="command_name">POVEČAJ</span>
            </div>
            <div
              className="command_wrapper"
              onClick={decreaseFontSize}
              onMouseEnter={() => setActiveIcon('out')}
              onMouseLeave={() => setActiveIcon('')}
            >
              <ZoomOutIcon active={activeIcon === 'out'} />{' '}
              <span className="command_name">POMANJŠAJ</span>
            </div>
            <div
              className="command_wrapper"
              onClick={toggleConfidence}
              onMouseEnter={() => setActiveIcon('conf')}
              onMouseLeave={() => setActiveIcon('')}
            >
              <ConfidenceIcon
                active={activeIcon === 'conf' || showConfidence}
              />{' '}
              <span className="command_name">ZAUPANJE</span>
            </div>
            <div
              className="command_wrapper"
              onClick={toggleTextAlign}
              onMouseEnter={() => setActiveIcon('justify')}
              onMouseLeave={() => setActiveIcon('')}
            >
              {isCentered && (
                <>
                  <TextAlignLeft active={activeIcon === 'justify'} />{' '}
                  <span className="command_name">PORAVNAJ LEVO</span>
                </>
              )}
              {!isCentered && (
                <>
                  <TextAlignCenter active={activeIcon === 'justify'} />{' '}
                  <span className="command_name">PORAVNAJ NA SREDINO</span>
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <div
        className={
          isCentered ? 'subtitles_overlay_center' : 'subtitles_overlay'
        }
      >
        {flowMode === FlowModes.UPLOAD && shownSubtitle && (
          <div
            className={
              isCentered ? 'subtitles_holder_center' : 'subtitles_holder'
            }
          >
            {shownSubtitle.firstRow && (
              <div
                className="subtitle"
                style={{
                  fontSize: subtitleFontSize,
                }}
              >
                {shownSubtitle.firstRow.split(' ').map((el, index) => {
                  return (
                    <span
                      key={index}
                      style={
                        showConfidence &&
                        shownSubtitle.firstRowConfidence[index] < 0.7
                          ? { color: 'orange' }
                          : {}
                      }
                    >
                      {el + ' '}
                    </span>
                  );
                })}
              </div>
            )}
            {shownSubtitle.secondRow && (
              <div
                className="subtitle"
                style={{
                  fontSize: subtitleFontSize,
                }}
              >
                {shownSubtitle.secondRow}
              </div>
            )}
          </div>
        )}

        {flowMode === FlowModes.LIVE && (
          <div
            className={
              isCentered ? 'subtitles_holder_center' : 'subtitles_holder'
            }
          >
            <div
              className="subtitle"
              style={{
                fontSize: subtitleFontSize,
                opacity: !staticRowSubtitle ? 0.6 : 1,
                textShadow: !staticRowSubtitle
                  ? 'none'
                  : '1px 1px 8px rgba(0,0,0,0.5)',
              }}
            >
              {staticRowSubtitle || bufferRowSubtitle}
            </div>
            <div
              className="subtitle"
              style={{
                fontSize: subtitleFontSize,
                opacity: 0.6,
                textShadow: 'none',
              }}
            >
              {!staticRowSubtitle ? '' : bufferRowSubtitle}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Subtitles;
