import React from 'react';
import Main from "./components/Main/Main";
import useCheckTokenAndSetUser from "./hooks/useCheckTokenAndSetUser";
import useLocalStorage from "./hooks/useLocalStorage";

const App: React.FC = () => {
  useCheckTokenAndSetUser([], true);
  useLocalStorage();

  return (
    <Main />
  );
};

export default App;
