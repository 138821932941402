import React, { useEffect, useRef, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { isAfter, isEqual, parse } from 'date-fns';

import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Slider from '@mui/material/Slider';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import LinearProgress from '@mui/material/LinearProgress';

import {
  SettingsPopupProps,
  Framework,
  IModel,
  IDomain,
  ILanguage,
  IConfigPatch,
  ISttStatus,
  IModelMetadata,
} from './ISettings';
import {
  setConfig,
  setRefetchTokens,
  setSttStatus,
  setUser,
  SET_DELAY,
  SET_AUDIO_CHUNK_SIZE,
  SET_UPLOAD_IN_LIVE_MODE,
} from '../../redux/actions/actions';
import { IStore, UserRoleEnums } from '../../redux/store/IStore';
import {
  GrantTypesEnums,
  keycloakTokensResponse,
  removeTokens,
  requestKeycloakTokens,
  setTokens,
} from '../../api/AuthenticationService';
import '../../styles/css/settingspopover.css';
import { AnimatePresence, motion } from 'framer-motion';

import useInterval from '../../hooks/useInterval';

import {
  getConfiguration,
  getSttStatus,
  setConfiguration,
} from '../../api/configurationService';
import jwtDecode from 'jwt-decode';
import { JWTDecoded } from '../Dashboard/IDashboard';
import { ClearIcon } from '../Icons/ClearIcon';
import { useDebounce } from '../../hooks/useDebounce';
import { useSnackbar } from 'notistack';
import './settings.css';
import useLocalStorageSave from '../../hooks/useLocalStorageSave';

const settingsVariants = {
  visible: {
    x: 0,
    transition: { ease: 'easeInOut', duration: 0.35 },
  },
  hidden: {
    x: -500,
    transition: { ease: 'easeInOut', duration: 0.35 },
  },
};

const getAllAllowedModels = (sttStatus: ISttStatus) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed)
    .flatMap((domain) => domain.models)
    .filter((model) => model.isAllowed);

const getAllAllowedDomains = (sttStatus: ISttStatus) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed);

const getAllAllowedLanguages = (sttStatus: ISttStatus) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed);

const getFilteredAllowedModels = (
  sttStatus: ISttStatus,
  frameworkValue: string,
  languageValue: string,
  domainValue: string
) =>
  sttStatus.frameworks
    .filter(
      (framework) => framework.isAllowed && framework.code === frameworkValue
    )
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed && language.code === languageValue)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed && domain.code === domainValue)
    .flatMap((domain) => domain.models)
    .filter((model) => model.isAllowed);

const getFilteredAllowedDomains = (
  sttStatus: ISttStatus,
  frameworkValue: string,
  languageValue: string
) =>
  sttStatus.frameworks
    .filter(
      (framework) => framework.isAllowed && framework.code === frameworkValue
    )
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed && language.code === languageValue)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed);

const getFilteredAllowedLanguages = (
  sttStatus: ISttStatus,
  frameworkValue: string
) =>
  sttStatus.frameworks
    .filter(
      (framework) => framework.isAllowed && framework.code === frameworkValue
    )
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed);

const getFilteredAllowedDomainsWithoutFramework = (
  sttStatus: ISttStatus,
  languageValue: string
) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed && language.code === languageValue)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed);

const getFilteredAllowedModelsWithoutFramework = (
  sttStatus: ISttStatus,
  languageValue: string,
  domainValue: string
) =>
  sttStatus.frameworks
    .filter((framework) => framework.isAllowed)
    .flatMap((framework) => framework.languages)
    .filter((language) => language.isAllowed && language.code === languageValue)
    .flatMap((language) => language.domains)
    .filter((domain) => domain.isAllowed && domain.code === domainValue)
    .flatMap((domain) => domain.models)
    .filter((model) => model.isAllowed);

const makeUniqueObjectArrayFromGivenKey = (array: any[], key: string) => {
  const filteredArray: any[] = [];
  array.forEach((element) => {
    const isAlreadyIncluded = filteredArray.some(
      (filteredElement) => filteredElement[key] === element[key]
    );
    if (!isAlreadyIncluded) filteredArray.push(element);
  });
  return filteredArray;
};

const SettingsDrawer: React.FC<SettingsPopupProps> = ({
  handleCloseSettings,
  show,
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const user = useSelector((state: IStore) => state.user);
  const [loadingCfg, setLoadingCfg] = useState(true);
  const [savingCfg, setSavingCfg] = useState(false);
  const [configError, setConfigError] = useState(false);
  const config = useSelector((state: IStore) => state.config);
  const sttStatus = useSelector((state: IStore) => state.sttStatus);
  const debouncedCfg = useDebounce(config, 1000, []);

  const [authFailed, setAuthFailed] = useState<boolean | string>(false);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [modelMetadata, setModelMetadata] = useState<
    IModelMetadata | undefined
  >(undefined);
  const [modelInfoModalVisible, setModelInfoVisible] = useState<boolean>(false); //null when info is not available, undefined when modal should be hidden

  const changedCfg = useRef<IConfigPatch>({});
  const settingsRef = useRef(null);
  const dictationCommandsSupport = useRef<boolean>(false);
  const diarizationSupport = useRef<boolean>(false);

  const [localLanguages, setLocalLanguages] = useState<ILanguage[] | null>(
    null
  );
  const [localDomains, setLocalDomains] = useState<IDomain[] | null>(null);
  const [localModels, setLocalModels] = useState<IModel[] | null>(null);

  // Local settings
  const delayRedux = useSelector((state: IStore) => state.delay);
  const [delay, setDelay] = useState<number>(delayRedux);
  const [setDelayLs] = useLocalStorageSave('delay');
  useEffect(() => setDelay(delayRedux), [delayRedux]);

  const uploadInLiveModeRedux = useSelector(
    (state: IStore) => state.uploadInLiveMode
  );
  const [uploadInLiveMode, setUploadInLiveMode] = useState<boolean>(
    uploadInLiveModeRedux
  );
  const [setUploadInLiveModeLs] = useLocalStorageSave('uploadInLiveMode');
  useEffect(
    () => setUploadInLiveMode(uploadInLiveModeRedux),
    [uploadInLiveModeRedux]
  );

  const audioChunkSizeRedux = useSelector(
    (state: IStore) => state.audioChunkSize
  );
  const [audioChunkSize, setAudioChunkSize] =
    useState<number>(audioChunkSizeRedux);
  const [setAudioChunkSizeLs] = useLocalStorageSave('audioChunkSize');
  useEffect(
    () => setAudioChunkSize(audioChunkSizeRedux),
    [audioChunkSizeRedux]
  );

  const resetState = () => {
    setAuthFailed(false);
    setUsername('');
    setPassword('');
  };

  const refreshConfiguration = async () => {
    const newSttStatus = (await getSttStatus()).data;
    const newConfig = (await getConfiguration()).data;

    if (!newConfig.stt?.framework?.value) {
      // No framework is selected. There are two use cases:
      //
      //  1. new users, that have no chosen model
      //  2. existing users, that have a model, but framework is <null> (non-admins)
      //
      // For the new users, we preselect newest available model. For existing users that
      // are not admins, we select the framework based on chosen model.
      let selectedFramework = '';
      let selectedLanguage = '';
      let selectedDomain = '';
      let selectedModel = '';

      if (
        !!newConfig.stt?.model?.value &&
        !!newConfig.stt?.domain?.value &&
        !!newConfig.stt?.language?.value
      ) {
        // User has selected model, but no framework. So we preselect the framework based on model/domain/language.
        newSttStatus.frameworks.every((framework) =>
          framework.languages.every((language) =>
            language.domains.every((domain) =>
              domain.models.every((model) => {
                if (
                  model.code === newConfig.stt.model.value &&
                  domain.code === newConfig.stt.domain.value &&
                  language.code === newConfig.stt.language.value
                ) {
                  selectedFramework = framework.code;
                  return false;
                }

                return true;
              })
            )
          )
        );

        if (!!selectedFramework) {
          newConfig.stt.framework.value = selectedFramework as Framework;
        }
      } else {
        // framework/language/domain/model aren't set (new user?), so we preselect the newest model
        let modelTs = new Date(0);

        for (const framework of newSttStatus.frameworks.filter(
          (f) => f.isAllowed
        )) {
          for (const language of framework.languages.filter(
            (l) => l.isAllowed
          )) {
            for (const domain of language.domains.filter((d) => d.isAllowed)) {
              for (const model of domain.models.filter((m) => m.isAllowed)) {
                // expecting version string as timestamp 'yyyyMMdd-HHmm', e.g. '20221017-1539'
                const version = model.code?.substring(0, 13);
                try {
                  const ts = parse(version, 'yyyyMMdd-HHmm', new Date());
                  const isNewerTimestamp = isAfter(ts, modelTs);
                  // if timestamps are equal, then we string compare both models:
                  //   the one that has any additional characters is considered newer,
                  //   e.g. '20210923-2017_6s' > '20210923-2017'
                  const isNewerVersion =
                    isEqual(ts, modelTs) && selectedModel < model.code;

                  if (isNewerTimestamp || isNewerVersion) {
                    modelTs = ts;
                    selectedModel = model.code;
                    selectedDomain = domain.code;
                    selectedLanguage = language.code;
                    selectedFramework = framework.code;
                  }
                } catch {
                  // could not parse the timestamp from model.code
                  if (!selectedModel) {
                    // We make sure at least something is selected, in case none of the models parsed as timestamp
                    selectedModel = model.code;
                    selectedDomain = domain.code;
                    selectedLanguage = language.code;
                    selectedFramework = framework.code;
                  }
                }
              }
            }
          }
        }

        newConfig.stt.framework.value = selectedFramework as Framework;
        newConfig.stt.language.value = selectedLanguage;
        newConfig.stt.domain.value = selectedDomain;
        newConfig.stt.model.value = selectedModel;
      }

      changedCfg.current = {
        ...changedCfg.current,
        stt: {
          ...changedCfg.current.stt,
          framework: {
            value: newConfig.stt.framework.value,
          },
          language: {
            value: newConfig.stt.language.value,
          },
          domain: {
            value: newConfig.stt.domain.value,
          },
          model: {
            value: newConfig.stt.model.value,
          },
        },
      };
    }

    if (newConfig.stt.framework.value && user?.isAdmin) {
      setLocalLanguages(
        makeUniqueObjectArrayFromGivenKey(
          getFilteredAllowedLanguages(
            newSttStatus,
            newConfig.stt.framework.value
          ),
          'code'
        )
      );
    } else {
      setLocalLanguages(
        makeUniqueObjectArrayFromGivenKey(
          getAllAllowedLanguages(newSttStatus),
          'code'
        )
      );
    }

    if (
      newConfig.stt.framework.value &&
      newConfig.stt.language.value &&
      user?.isAdmin
    ) {
      setLocalDomains(
        makeUniqueObjectArrayFromGivenKey(
          getFilteredAllowedDomains(
            newSttStatus,
            newConfig.stt.framework.value,
            newConfig.stt.language.value
          ),
          'code'
        )
      );
    } else if (newConfig.stt.language.value) {
      setLocalDomains(
        makeUniqueObjectArrayFromGivenKey(
          getFilteredAllowedDomainsWithoutFramework(
            newSttStatus,
            newConfig.stt.language.value
          ),
          'code'
        )
      );
    } else {
      setLocalDomains(
        makeUniqueObjectArrayFromGivenKey(
          getAllAllowedDomains(newSttStatus),
          'code'
        )
      );
    }

    if (
      newConfig.stt.framework.value &&
      newConfig.stt.language.value &&
      newConfig.stt.domain.value &&
      user?.isAdmin
    ) {
      setLocalModels(
        makeUniqueObjectArrayFromGivenKey(
          getFilteredAllowedModels(
            newSttStatus,
            newConfig.stt.framework.value,
            newConfig.stt.language.value,
            newConfig.stt.domain.value
          ),
          'code'
        )
      );
    } else if (newConfig.stt.language.value && newConfig.stt.domain.value) {
      setLocalModels(
        makeUniqueObjectArrayFromGivenKey(
          getFilteredAllowedModelsWithoutFramework(
            newSttStatus,
            newConfig.stt.language.value,
            newConfig.stt.domain.value
          ),
          'code'
        )
      );
    } else {
      setLocalModels(
        makeUniqueObjectArrayFromGivenKey(
          getAllAllowedModels(newSttStatus),
          'code'
        )
      );
    }

    dispatch(setConfig(newConfig));
    dispatch(setSttStatus(newSttStatus));
    setLoadingCfg(false);
  };

  //this effect updates status
  useEffect(() => {
    if (!user || !user.isAuthenticated) return;

    refreshConfiguration();
  }, [user]);

  useInterval(
    () => {
      if (user && user.isAuthenticated && user.username) {
        refreshConfiguration();
      }
    },
    10000,
    [config, user?.isAuthenticated, user?.username]
  );

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!user) {
      try {
        const tokens = await requestKeycloakTokens(
          username,
          password,
          GrantTypesEnums.PASSWORD
        );
        const data = tokens.data as keycloakTokensResponse;

        if (data) {
          setTokens({
            access_token: data.access_token,
            refresh_token: data.refresh_token,
          });

          const decodedTokenData: JWTDecoded = jwtDecode(data.access_token);

          setAuthFailed(false);
          if (
            !decodedTokenData.realm_access.roles.includes(
              UserRoleEnums.EDITOR_USER
            )
          ) {
            removeTokens();
            dispatch(setUser(null));
            dispatch(setRefetchTokens(null));
            setAuthFailed(
              'Uporabniški račun nima pravic za uporabo True-Bar platforme'
            );
            const homeBtns =
              document.getElementsByClassName('buttons_container')[0];
            homeBtns.classList.add('invalid-auth');
            setTimeout(() => {
              homeBtns.classList.remove('invalid-auth');
            }, 500);
            return;
          }

          dispatch(
            setUser({
              username,
              accessToken: data.access_token,
              refreshToken: data.refresh_token,
              isAuthenticated: true,
              userRoles: decodedTokenData.realm_access.roles,
              isEditorUser: decodedTokenData.realm_access.roles.includes(
                UserRoleEnums.EDITOR_USER
              ),
            })
          );
          dispatch(setRefetchTokens(data.expires_in));
        }
      } catch (e) {
        // console.log(e);
        removeTokens();
        dispatch(setUser(null));
        dispatch(setRefetchTokens(null));
        setAuthFailed('Vneseni podatki so napačni');
        const homeBtns =
          document.getElementsByClassName('buttons_container')[0];
        homeBtns.classList.add('invalid-auth');
        setTimeout(() => {
          homeBtns.classList.remove('invalid-auth');
        }, 500);
      }

      return;
    } else {
      // if (fileUploadIsInProgress) {
      //   return;
      // }
      // if (config) {
      //   const configWoOptions = toConfigWoOptions(config);
      //   try {
      // const r = await setConfiguration(configWoOptions);
      //     // console.log(r, 'cfg pathcin res');
      //   } catch (e) {
      //     // to-do: handle config patch error
      //   }
      //   handleCloseSettings();
      // }
    }
  };

  const handleLogoutClick = () => {
    batch(() => {
      dispatch(setRefetchTokens(null));
      dispatch(setUser(null));
      dispatch(setConfig(null));
      dispatch(setSttStatus(null));
    });

    removeTokens();
    resetState();
    setLocalLanguages(null);
    setLocalDomains(null);
    setLocalModels(null);
  };

  const closeModelInfoModal = () => {
    setModelMetadata(undefined);
    setModelInfoVisible(false);
  };

  const preventOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const openModelInfoModal = (modelInfo: IModelMetadata | undefined) => {
    setModelMetadata(modelInfo);
    setModelInfoVisible(true);
  };

  const onModelInfoClick = () => {
    if (!sttStatus || !config) return false;

    const framework = sttStatus.frameworks.find(
      (framework) => framework.code === config.stt.framework.value
    );
    if (!framework) return false;

    const language = framework.languages.find(
      (language) => language.code === config.stt.language.value
    );
    if (!language) return false;

    const domain = language.domains.find(
      (domain) => domain.code === config.stt.domain.value
    );
    if (!domain) return false;

    const model = domain.models.find(
      (model) => model.code === config.stt.model.value
    );
    if (!model) return false;

    openModelInfoModal(model.metadata);
  };

  const handleFrameworkChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!sttStatus || !config) return;

    const configCopy = { ...config };

    const newFrameworkValue = event.target.value;

    const languages = getFilteredAllowedLanguages(sttStatus, newFrameworkValue);
    if (languages.length <= 0) {
      enqueueSnackbar(
        `Na voljo ni veljavnih jezikov za izbrano ogrodje ${newFrameworkValue}.`,
        {
          variant: 'error',
        }
      );
      return;
    }
    const language = languages[0];

    const domains = getFilteredAllowedDomains(
      sttStatus,
      newFrameworkValue,
      language.code
    );
    if (domains.length <= 0) {
      enqueueSnackbar(
        `Na voljo ni veljavnih domen za izbrani jezik ${language.code}.`,
        {
          variant: 'error',
        }
      );
      return;
    }
    const domain = domains[0];

    const models = getFilteredAllowedModels(
      sttStatus,
      newFrameworkValue,
      language.code,
      domain.code
    );
    if (domains.length <= 0) {
      enqueueSnackbar(
        `Na voljo ni veljavnih modelov za izbrano domeno ${domain.code}.`,
        {
          variant: 'error',
        }
      );
      return;
    }
    const model = models[0];

    configCopy.stt.framework.value = newFrameworkValue as Framework;
    configCopy.stt.language.value = language.code;
    configCopy.stt.domain.value = domain.code;
    configCopy.stt.model.value = model.code;

    dictationCommandsSupport.current = model.dictationCommandsSupport;
    diarizationSupport.current = model.diarizationSupport;

    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        framework: {
          value: newFrameworkValue as Framework,
        },
        language: {
          value: language.code,
        },
        domain: {
          value: domain.code,
        },
        model: {
          value: model.code,
        },
        enableDiarization: {
          isAllowed: model.diarizationSupport,
          value: model.diarizationSupport
            ? configCopy.stt.enableDiarization.value
            : false,
        },
        enableDictatedPunctuations: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport
            ? configCopy.stt.enableDictatedPunctuations.value
            : false,
        },
        enableDictatedCommands: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport
            ? configCopy.stt.enableDictatedCommands.value
            : false,
        },
      },
    };

    setLocalLanguages(languages);
    setLocalDomains(domains);
    setLocalModels(models);
    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const configCopy = { ...config };

    const newLanguageValue = event.target.value;
    const currentFrameworkValue = configCopy.stt.framework.value;

    const domains =
      currentFrameworkValue && user?.isAdmin
        ? makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedDomains(
              sttStatus,
              currentFrameworkValue,
              newLanguageValue
            ),
            'code'
          )
        : makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedDomainsWithoutFramework(
              sttStatus,
              newLanguageValue
            ),
            'code'
          );

    if (domains.length <= 0) {
      enqueueSnackbar(
        `Na voljo ni veljavnih domen za izbrani jezik ${newLanguageValue}.`,
        {
          variant: 'error',
        }
      );
      return;
    }
    const domain = domains[0];

    const models =
      currentFrameworkValue && user?.isAdmin
        ? makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModels(
              sttStatus,
              currentFrameworkValue,
              newLanguageValue,
              domain.code
            ),
            'code'
          )
        : makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModelsWithoutFramework(
              sttStatus,
              newLanguageValue,
              domain.code
            ),
            'code'
          );

    if (models.length <= 0) {
      enqueueSnackbar(
        `Na voljo ni veljavnih modelov za izbrano domeno ${domain.code}.`,
        {
          variant: 'error',
        }
      );
      return;
    }
    const model = models[0];

    configCopy.stt.model.value = model.code;

    if (user?.isAdmin) {
      configCopy.stt.language.value = newLanguageValue;
      configCopy.stt.domain.value = domain.code;
    } else {
      // change framework as well according to chosen model
      for (const framework of sttStatus.frameworks.filter((f) => f.isAllowed)) {
        for (const language of framework.languages.filter((l) => l.isAllowed)) {
          for (const domain of language.domains.filter((d) => d.isAllowed)) {
            for (const model of domain.models.filter((m) => m.isAllowed)) {
              if (model.code === configCopy.stt.model.value) {
                configCopy.stt.language.value = language.code;
                configCopy.stt.domain.value = domain.code;
                configCopy.stt.framework.value = framework.code as Framework;
              }
            }
          }
        }
      }
    }

    dictationCommandsSupport.current = model.dictationCommandsSupport;
    diarizationSupport.current = model.diarizationSupport;

    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        model: {
          value: configCopy.stt.model.value,
        },
        framework: {
          value: configCopy.stt.framework.value,
        },
        language: {
          value: configCopy.stt.language.value,
        },
        domain: {
          value: configCopy.stt.domain.value,
        },
        enableDiarization: {
          isAllowed: model.diarizationSupport,
          value: model.diarizationSupport
            ? configCopy.stt.enableDiarization.value
            : false,
        },
        enableDictatedPunctuations: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport
            ? configCopy.stt.enableDictatedPunctuations.value
            : false,
        },
        enableDictatedCommands: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport
            ? configCopy.stt.enableDictatedCommands.value
            : false,
        },
      },
    };

    setLocalDomains(domains);
    setLocalModels(models);
    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newDomainValue = event.target.value;

    if (config.stt.language.value === '') {
      enqueueSnackbar(
        `Izbrali ste domeno ${newDomainValue} brez izbranega jezika. To je neveljavna konfiguracija`,
        {
          variant: 'error',
        }
      );
      return;
    }

    const configCopy = { ...config };
    const currentFrameworkValue = configCopy.stt.framework.value;
    const currentLanguageValue = configCopy.stt.language.value;

    const models =
      currentFrameworkValue && user?.isAdmin
        ? makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModels(
              sttStatus,
              currentFrameworkValue,
              currentLanguageValue,
              newDomainValue
            ),
            'code'
          )
        : makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModelsWithoutFramework(
              sttStatus,
              currentLanguageValue,
              newDomainValue
            ),
            'code'
          );

    if (models.length <= 0) {
      enqueueSnackbar(
        `Na voljo ni veljavnih modelov za izbrano domeno ${newDomainValue}.`,
        {
          variant: 'error',
        }
      );
      return;
    }
    const model = models[0];

    configCopy.stt.model.value = model.code;

    if (user?.isAdmin) {
      configCopy.stt.domain.value = newDomainValue;
    } else {
      // change framework as well according to chosen model
      for (const framework of sttStatus.frameworks.filter((f) => f.isAllowed)) {
        for (const language of framework.languages.filter((l) => l.isAllowed)) {
          for (const domain of language.domains.filter((d) => d.isAllowed)) {
            for (const model of domain.models.filter((m) => m.isAllowed)) {
              if (model.code === configCopy.stt.model.value) {
                configCopy.stt.language.value = language.code;
                configCopy.stt.domain.value = domain.code;
                configCopy.stt.framework.value = framework.code as Framework;
              }
            }
          }
        }
      }
    }

    dictationCommandsSupport.current = model.dictationCommandsSupport;
    diarizationSupport.current = model.diarizationSupport;

    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        model: {
          value: configCopy.stt.model.value,
        },
        framework: {
          value: configCopy.stt.framework.value,
        },
        language: {
          value: configCopy.stt.language.value,
        },
        domain: {
          value: configCopy.stt.domain.value,
        },
        enableDiarization: {
          isAllowed: model.diarizationSupport,
          value: model.diarizationSupport
            ? configCopy.stt.enableDiarization.value
            : false,
        },
        enableDictatedPunctuations: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport
            ? configCopy.stt.enableDictatedPunctuations.value
            : false,
        },
        enableDictatedCommands: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport
            ? configCopy.stt.enableDictatedCommands.value
            : false,
        },
      },
    };

    setLocalModels(models);
    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newModelValue = event.target.value;

    if (config.stt.language.value === '') {
      enqueueSnackbar(
        `Izbrali ste model ${newModelValue} brez izbranega jezika. To je neveljavna konfiguracija`,
        {
          variant: 'error',
        }
      );
      return;
    }

    if (config.stt.domain.value === '') {
      enqueueSnackbar(
        `Izbrali ste model ${newModelValue} brez izbrane domene. To je neveljavna konfiguracija`,
        {
          variant: 'error',
        }
      );
      return;
    }

    const configCopy = { ...config };
    const currentFrameworkValue = configCopy.stt.framework.value;
    const currentLanguageValue = configCopy.stt.language.value;
    const currentDomainValue = configCopy.stt.domain.value;

    const models =
      currentFrameworkValue && user?.isAdmin
        ? makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModels(
              sttStatus,
              currentFrameworkValue,
              currentLanguageValue,
              currentDomainValue
            ),
            'code'
          )
        : makeUniqueObjectArrayFromGivenKey(
            getFilteredAllowedModelsWithoutFramework(
              sttStatus,
              currentLanguageValue,
              currentDomainValue
            ),
            'code'
          );

    const model = models.find((model) => model.code === newModelValue);
    configCopy.stt.model.value = model.code;
    if (!user?.isAdmin) {
      // change framework as well according to chosen model
      for (const framework of sttStatus.frameworks.filter((f) => f.isAllowed)) {
        for (const language of framework.languages.filter((l) => l.isAllowed)) {
          for (const domain of language.domains.filter((d) => d.isAllowed)) {
            for (const model of domain.models.filter((m) => m.isAllowed)) {
              if (model.code === configCopy.stt.model.value) {
                configCopy.stt.language.value = language.code;
                configCopy.stt.domain.value = domain.code;
                configCopy.stt.framework.value = framework.code as Framework;
              }
            }
          }
        }
      }
    }

    dictationCommandsSupport.current = model.dictationCommandsSupport;
    diarizationSupport.current = model.diarizationSupport;

    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        model: {
          value: configCopy.stt.model.value,
        },
        framework: {
          value: configCopy.stt.framework.value,
        },
        language: {
          value: configCopy.stt.language.value,
        },
        domain: {
          value: configCopy.stt.domain.value,
        },
        enableDiarization: {
          isAllowed: model.diarizationSupport,
          value: model.diarizationSupport
            ? configCopy.stt.enableDiarization.value
            : false,
        },
        enableDictatedPunctuations: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport
            ? configCopy.stt.enableDictatedPunctuations.value
            : false,
        },
        enableDictatedCommands: {
          isAllowed: model.dictationCommandsSupport,
          value: model.dictationCommandsSupport
            ? configCopy.stt.enableDictatedCommands.value
            : false,
        },
      },
    };

    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handleDenormalizationToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!sttStatus || !config) return;

    const newDenormalisationValue = event.target.checked;

    if (newDenormalisationValue === config.nlp.enableDenormalization.value)
      return;

    const configCopy = { ...config };

    configCopy.nlp.enableDenormalization.value = newDenormalisationValue;
    changedCfg.current = {
      ...changedCfg.current,
      nlp: {
        ...changedCfg.current.nlp,
        enableDenormalization: {
          value: newDenormalisationValue,
        },
      },
    };

    dispatch(setConfig(configCopy));
  };

  const handleTruecasingToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!sttStatus || !config) return;

    const newTruecasingValue = event.target.checked;

    if (newTruecasingValue === config.nlp.enableTruecasing.value) return;

    const configCopy = { ...config };

    configCopy.nlp.enableTruecasing.value = newTruecasingValue;
    changedCfg.current = {
      ...changedCfg.current,
      nlp: {
        ...changedCfg.current.nlp,
        enableTruecasing: {
          value: newTruecasingValue,
        },
      },
    };

    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handlePunctuationToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!sttStatus || !config) return;

    const newPunctuationValue = event.target.checked;

    if (newPunctuationValue === config.nlp.punctuation.enabled.value) return;

    const configCopy = { ...config };

    configCopy.nlp.punctuation.enabled.value = newPunctuationValue;
    changedCfg.current = {
      ...changedCfg.current,
      nlp: {
        ...changedCfg.current.nlp,
        punctuation: {
          ...changedCfg.current.nlp?.punctuation,
          enabled: {
            ...changedCfg.current.nlp?.punctuation?.enabled,
            value: newPunctuationValue,
          },
        },
      },
    };

    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handleDiarizationToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!sttStatus || !config) return;

    const newDiarizationValue = event.target.checked;

    if (newDiarizationValue === config.stt.enableDiarization.value) return;

    const configCopy = { ...config };

    configCopy.stt.enableDiarization.value = newDiarizationValue;
    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        enableDiarization: {
          value: newDiarizationValue,
        },
      },
    };

    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handleDictatedCommandsToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!sttStatus || !config) return;

    const newDictatedCommandsValue = event.target.checked;

    if (newDictatedCommandsValue === config.stt.enableDictatedCommands.value)
      return;

    const configCopy = { ...config };

    configCopy.stt.enableDictatedCommands.value = newDictatedCommandsValue;
    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        enableDictatedCommands: {
          value: newDictatedCommandsValue,
        },
      },
    };

    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handleDictatedPonctuationsToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!sttStatus || !config) return;

    const newDictatedPonctuationsValue = event.target.checked;

    if (
      newDictatedPonctuationsValue ===
      config.stt.enableDictatedPunctuations.value
    )
      return;

    const configCopy = { ...config };

    configCopy.stt.enableDictatedPunctuations.value =
      newDictatedPonctuationsValue;
    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        enableDictatedPunctuations: {
          value: newDictatedPonctuationsValue,
        },
      },
    };

    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handleInterimsToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!sttStatus || !config) return;

    const newInterimsValue = event.target.checked;

    if (newInterimsValue === config.stt.enableInterimTranscripts.value) return;

    const configCopy = { ...config };

    configCopy.stt.enableInterimTranscripts.value = newInterimsValue;
    changedCfg.current = {
      ...changedCfg.current,
      stt: {
        ...changedCfg.current.stt,
        enableInterimTranscripts: {
          value: newInterimsValue,
        },
      },
    };

    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  const handleRealFinalsToggle = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!sttStatus || !config) return;

    const newRealFinalsValue = event.target.checked;

    if (newRealFinalsValue === config.nlp.punctuation.enableRealFinals.value)
      return;

    const configCopy = { ...config };

    configCopy.nlp.punctuation.enableRealFinals.value = newRealFinalsValue;

    changedCfg.current = {
      ...changedCfg.current,
      nlp: {
        ...changedCfg.current.nlp,
        punctuation: {
          ...changedCfg.current.nlp?.punctuation,
          enableRealFinals: {
            ...changedCfg.current.nlp?.punctuation?.enableRealFinals,
            value: newRealFinalsValue,
          },
        },
      },
    };

    setSavingCfg(true);
    dispatch(setConfig(configCopy));
  };

  //this effect executes actual backend patch when debounce timeous out
  useEffect(() => {
    if (!debouncedCfg) return;
    if (!changedCfg.current.nlp && !changedCfg.current.stt) return;

    setConfiguration(changedCfg.current)
      .then((r) => {
        changedCfg.current = {};
      })
      .catch((e) => {
        changedCfg.current = {};
      })
      .finally(() => {
        setSavingCfg(false);
      });
  }, [debouncedCfg]);

  const handleAwayClick = () => {
    if (!savingCfg) {
      handleCloseSettings();
    }
  };

  return (
    <>
      <AnimatePresence>
        {show && (
          <>
            <div className="modal_wrapper" onClick={handleAwayClick} />
            <motion.form
              ref={settingsRef}
              className="settings_form"
              onSubmit={onSubmit}
              autoComplete="off"
              variants={settingsVariants}
              initial="hidden"
              animate="visible"
              exit="hidden"
            >
              <button
                type="button"
                style={{
                  // color: '#707070',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  top: 25,
                  right: 25,
                }}
                onClick={() => {
                  config && setConfiguration(changedCfg.current);
                  handleAwayClick();
                }}
              >
                <ClearIcon />
              </button>
              <div className="settings_container">
                <div className="tittle_style">Nastavitve</div>
                <div className="credentials_wrapper">
                  <div className="credentials_title_line">
                    <div>Avtentikacija</div>
                  </div>
                  <div className="credentials_userpwline">
                    {!user ? (
                      <>
                        {/* {!connected ? (
                                                <LinearProgress
                                                    style={{ width: '100%', marginTop: '10px' }}
                                                    color={'secondary'}
                                                />
                                            ) : ( */}
                        <>
                          <TextField
                            value={username}
                            error={typeof authFailed === 'string'}
                            name="username"
                            placeholder="Uporabniško ime"
                            margin="dense"
                            // autoComplete="current-username"
                            onChange={(e) => {
                              setUsername(e.target.value);
                              setAuthFailed(false);
                            }}
                            helperText={authFailed || null}
                          />
                          <TextField
                            value={password}
                            error={typeof authFailed === 'string'}
                            name="password"
                            placeholder="Geslo"
                            type="password"
                            autoComplete="current-password"
                            margin="dense"
                            onChange={(e) => {
                              setPassword(e.target.value);
                              setAuthFailed(false);
                            }}
                            helperText={authFailed || null}
                          />
                        </>
                        {/* )} */}
                      </>
                    ) : (
                      <>
                        {/* <TextField
                        // defaultValue={user.username}
                        value={user.username}
                        margin="dense"
                        name="current-user"
                        // disabled
                      /> */}
                        <div
                          className="checkbox_wrapper MuiInputBase-input"
                          style={{ marginTop: '8px', marginBottom: '0px' }}
                        >
                          <div>{user.username}</div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginTop: '20px',
                          }}
                        >
                          <button
                            type="button"
                            onClick={handleLogoutClick}
                            className="cancel_button"
                            style={{
                              display: 'inline-flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              marginRight: 0,
                            }}
                          >
                            ODJAVA
                          </button>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {user && !loadingCfg && configError && (
                  <p>
                    Prišlo je do napake pri generiranju nastavitev. Poskusite
                    osvežiti stran.
                  </p>
                )}
                {user && loadingCfg ? (
                  <LinearProgress
                    style={{ width: '100%', marginTop: '10px' }}
                    color={'secondary'}
                  />
                ) : (
                  !loadingCfg &&
                  !configError &&
                  config &&
                  sttStatus &&
                  user && (
                    <>
                      <div className="transcription_wrapper">
                        <div className="transcription_title_line">
                          <div>Predvajanje</div>
                        </div>

                        <div className="checkbox_wrapper">
                          <div style={{ flex: '4' }}>Audio buffer</div>
                          <Slider
                            style={{ flex: '8' }}
                            aria-label="Velikost audio paketa"
                            value={Math.log2(audioChunkSize)}
                            valueLabelFormat={(value) => `${2 ** value} bytes`}
                            valueLabelDisplay="auto"
                            marks
                            step={1}
                            min={9}
                            max={14}
                            track={false}
                            onChange={(e, newValue) => {
                              const value =
                                2 **
                                (newValue instanceof Array
                                  ? newValue[0]
                                  : newValue);
                              setAudioChunkSize(value);
                              dispatch({
                                type: SET_AUDIO_CHUNK_SIZE,
                                audioChunkSize: value,
                              });
                              setAudioChunkSizeLs(value);
                            }}
                          />
                        </div>
                        <div className="checkbox_wrapper">
                          <div>Zamik (ms)</div>
                          <TextField
                            id="delay_textfield"
                            variant="standard"
                            type="number"
                            margin="dense"
                            name="delay"
                            value={delay}
                            onChange={(e) => {
                              const value = e.target.value as unknown as number;
                              dispatch({ type: SET_DELAY, delay: value });
                              setDelay(value);
                              setDelayLs(value);
                            }}
                          />
                        </div>
                        <div className="checkbox_wrapper">
                          <div>Naloži posnetek v živo</div>
                          <Checkbox
                            checked={uploadInLiveMode}
                            onChange={(e) => {
                              setUploadInLiveMode(e.target.checked);
                              dispatch({
                                type: SET_UPLOAD_IN_LIVE_MODE,
                                uploadInLiveMode: e.target.checked,
                              });
                              setUploadInLiveModeLs(e.target.checked);
                            }}
                            value="uploadInLiveMode"
                            color="primary"
                          />
                        </div>
                      </div>

                      <div className="transcription_wrapper">
                        {savingCfg && (
                          <>
                            <LinearProgress
                              style={{
                                width: '100%',
                                marginTop: '-14px',
                                marginBottom: '10px',
                              }}
                              color={'secondary'}
                            />
                            <div className="saving_config_wrapper"></div>
                          </>
                        )}
                        <div className="transcription_title_line">
                          <div>Transkripcija</div>
                        </div>

                        <div className="options_wrapper">
                          {user?.isAdmin && (
                            <TextField
                              style={{
                                opacity: config.stt.framework.isAllowed
                                  ? 1
                                  : 0.4,
                                fontSize: '14px',
                              }}
                              disabled={!config.stt.framework.isAllowed}
                              name="framework"
                              select
                              value={
                                config.stt.framework.value
                                  ? config.stt.framework.value
                                  : ''
                              }
                              onChange={handleFrameworkChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className="input_adornment"
                                  >
                                    Ogrodje
                                  </InputAdornment>
                                ),
                              }}
                            >
                              {sttStatus.frameworks.map((framework) => {
                                return (
                                  <MenuItem
                                    disabled={!framework.isAllowed}
                                    key={framework.code}
                                    value={framework.code}
                                  >
                                    {framework.code}
                                  </MenuItem>
                                );
                              })}
                            </TextField>
                          )}
                          <TextField
                            style={{
                              opacity: config.stt.language.isAllowed ? 1 : 0.4,
                              fontSize: '14px',
                            }}
                            disabled={!config.stt.language.isAllowed}
                            name="transcriptionLanguage"
                            select
                            value={config.stt.language.value}
                            onChange={handleLanguageChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="input_adornment"
                                >
                                  Jezik
                                </InputAdornment>
                              ),
                            }}
                          >
                            {localLanguages &&
                              localLanguages.map((language) => {
                                return (
                                  <MenuItem
                                    disabled={!language.isAllowed}
                                    key={language.code}
                                    value={language.code}
                                    style={
                                      !language.isAllowed
                                        ? {
                                            opacity: '0.4',
                                            pointerEvents: 'none',
                                            touchAction: 'none',
                                          }
                                        : {}
                                    }
                                  >
                                    {language.code}
                                  </MenuItem>
                                );
                              })}
                          </TextField>

                          <TextField
                            style={{
                              opacity: config.stt.domain.isAllowed ? 1 : 0.4,
                              fontSize: '14px',
                            }}
                            disabled={
                              !config.stt.domain.isAllowed ||
                              config.stt.language.value === ''
                            }
                            // inputRef={register}
                            name="transcriptionDomain"
                            select
                            value={config.stt.domain.value}
                            onChange={handleDomainChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="input_adornment"
                                >
                                  <p>Domena</p>
                                </InputAdornment>
                              ),
                            }}
                          >
                            {localDomains &&
                              localDomains.map((domain) => (
                                <MenuItem
                                  disabled={!domain.isAllowed}
                                  key={domain.code}
                                  value={domain.code}
                                  style={
                                    !domain.isAllowed
                                      ? {
                                          opacity: '0.4',
                                          pointerEvents: 'none',
                                          touchAction: 'none',
                                        }
                                      : {}
                                  }
                                >
                                  {domain.code}
                                </MenuItem>
                              ))}
                          </TextField>

                          <TextField
                            style={{
                              opacity: config.stt.model.isAllowed ? 1 : 0.4,
                              fontSize: '14px',
                            }}
                            disabled={
                              !config.stt.model.isAllowed ||
                              config.stt.domain.value === ''
                            }
                            // inputRef={register}
                            name="transcriptionModelVersion"
                            select
                            value={config.stt.model.value}
                            onChange={handleModelChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment
                                  position="start"
                                  className="input_adornment"
                                >
                                  <p>Verzija</p>
                                </InputAdornment>
                              ),
                            }}
                          >
                            {localModels &&
                              localModels.map((option) => (
                                <MenuItem
                                  key={option.code}
                                  value={option.code}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                    ...((!option.isAvailable ||
                                      !option.isAllowed) && {
                                      opacity: '0.4',
                                      pointerEvents: 'none',
                                      touchAction: 'none',
                                    }),
                                  }}
                                >
                                  <span>{option.code}</span>
                                  {/* <span
                                style={{
                                  width: 8,
                                  height: 8,
                                  borderRadius: option.realtime ? '50%' : 0,
                                  backgroundColor: option.realtime ? '#ff6666' : '#31a0ff',
                                  marginLeft: 6,
                                }}
                              /> */}
                                </MenuItem>
                              ))}
                          </TextField>

                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: config.nlp.enableDenormalization
                                .isAllowed
                                ? 1
                                : 0.4,
                              pointerEvents: config.nlp.enableDenormalization
                                .isAllowed
                                ? 'all'
                                : 'none',
                            }}
                          >
                            <div>Denormalizacija</div>
                            <Checkbox
                              disabled={
                                !config.nlp.enableDenormalization.isAllowed
                              }
                              checked={config.nlp.enableDenormalization.value}
                              onChange={handleDenormalizationToggle}
                              color="primary"
                            />
                          </div>

                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: config.nlp.enableTruecasing.isAllowed
                                ? 1
                                : 0.4,
                              pointerEvents: config.nlp.enableDenormalization
                                .isAllowed
                                ? 'all'
                                : 'none',
                            }}
                          >
                            <div>Samodejno postavljanje velikih začetnic</div>
                            <Checkbox
                              disabled={!config.nlp.enableTruecasing.isAllowed}
                              checked={config.nlp.enableTruecasing.value}
                              onChange={handleTruecasingToggle}
                              color="primary"
                            />
                          </div>

                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: config.nlp.punctuation.enabled.isAllowed
                                ? 1
                                : 0.4,
                              pointerEvents: config.nlp.punctuation.enabled
                                .isAllowed
                                ? 'all'
                                : 'none',
                            }}
                          >
                            <div>Samodejno postavljanje ločil</div>
                            <Checkbox
                              disabled={
                                !config.nlp.punctuation.enabled.isAllowed
                              }
                              checked={config.nlp.punctuation.enabled.value}
                              onChange={handlePunctuationToggle}
                              color="primary"
                            />
                          </div>

                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity:
                                config.stt.enableDiarization.isAllowed &&
                                diarizationSupport.current
                                  ? 1
                                  : 0.4,
                              pointerEvents:
                                diarizationSupport.current ||
                                config.stt.enableDiarization.isAllowed
                                  ? 'all'
                                  : 'none',
                            }}
                          >
                            <div>Samodejno ločevanje govorcev</div>
                            <Checkbox
                              disabled={
                                !config.stt.enableDiarization.isAllowed ||
                                !diarizationSupport.current
                              }
                              checked={config.stt.enableDiarization.value}
                              onChange={handleDiarizationToggle}
                              color="primary"
                            />
                          </div>

                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity:
                                config.stt.enableDictatedPunctuations
                                  .isAllowed && dictationCommandsSupport.current
                                  ? 1
                                  : 0.4,
                            }}
                          >
                            <div>Narekovana ločila</div>
                            <Checkbox
                              disabled={
                                !config.stt.enableDictatedPunctuations
                                  .isAllowed ||
                                dictationCommandsSupport.current === false
                              }
                              checked={
                                config.stt.enableDictatedPunctuations.value
                              }
                              onChange={handleDictatedPonctuationsToggle}
                              color="primary"
                            />
                          </div>

                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity:
                                config.stt.enableDictatedCommands.isAllowed &&
                                dictationCommandsSupport.current
                                  ? 1
                                  : 0.4,
                            }}
                          >
                            <div>Narekovani ukazi</div>
                            <Checkbox
                              disabled={
                                !config.stt.enableDictatedCommands.isAllowed ||
                                dictationCommandsSupport.current === false
                              }
                              checked={config.stt.enableDictatedCommands.value}
                              onChange={handleDictatedCommandsToggle}
                              color="primary"
                            />
                          </div>

                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: config.stt.enableInterimTranscripts
                                .isAllowed
                                ? 1
                                : 0.4,
                              pointerEvents: config.stt.enableInterimTranscripts
                                .isAllowed
                                ? 'all'
                                : 'none',
                            }}
                          >
                            <div style={{ fontStyle: 'italic' }}>
                              Prikazuj delne transkripte
                            </div>
                            <Checkbox
                              disabled={
                                !config.stt.enableInterimTranscripts.isAllowed
                              }
                              checked={
                                config.stt.enableInterimTranscripts.value
                              }
                              onChange={handleInterimsToggle}
                              color="primary"
                            />
                          </div>

                          <div
                            className="checkbox_wrapper"
                            style={{
                              opacity: config.nlp.punctuation.enableRealFinals
                                .isAllowed
                                ? 1
                                : 0.4,
                              pointerEvents: config.nlp.punctuation
                                .enableRealFinals.isAllowed
                                ? 'all'
                                : 'none',
                            }}
                          >
                            <div style={{ fontStyle: 'italic' }}>
                              Razbijaj končne transkripte na povedi
                            </div>
                            <Checkbox
                              disabled={
                                !config.nlp.punctuation.enableRealFinals
                                  .isAllowed
                              }
                              checked={
                                config.nlp.punctuation.enableRealFinals.value
                              }
                              onChange={handleRealFinalsToggle}
                              color="primary"
                            />
                          </div>
                          {user?.isAdmin && (
                            <div className="checkbox_wrapper">
                              <div style={{ fontStyle: 'italic' }}>
                                Prikaži lastnosti modela
                              </div>
                              <div onClick={onModelInfoClick}>
                                <img
                                  src="info.svg"
                                  alt="Info svg"
                                  style={{
                                    width: 24,
                                    height: 24,
                                    cursor: 'pointer',
                                    paddingRight: '9px',
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )
                )}
              </div>
              <div className="settings_footer">
                <div className="settings_buttons_container">
                  {/* <button type="button" onClick={handleCloseSettings} className="cancel_button">
                  ZAPRI
                </button> */}
                  {!user && (
                    <button type="submit" className="save_button">
                      PRIJAVA
                    </button>
                  )}
                </div>
                {/* <div className="footer_info">
           © <b>Vitasis</b> & <b>Laboratorij za podatkovne tehnologije</b>, UL-FRI
             </div>*/}
              </div>
            </motion.form>
          </>
        )}
      </AnimatePresence>
      {config && modelInfoModalVisible && (
        <div
          onClick={closeModelInfoModal}
          style={{
            zIndex: 35,
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#0000001A',
          }}
        >
          <div
            onClick={preventOnClick}
            style={{
              paddingLeft: 39,
              paddingTop: 33,
              paddingBottom: 37,
              paddingRight: 44,
              width: '40%',
              backgroundColor: '#F2F2F2',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {(modelMetadata && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 59,
                  }}
                >
                  <p
                    style={{
                      margin: 0,
                      fontSize: 36,
                      fontWeight: 300,
                      fontFamily: 'Roboto',
                    }}
                  >
                    {`${config.stt.language.value}:${config.stt.domain.value}:${config.stt.model.value}`}
                  </p>
                  <button type="button" onClick={closeModelInfoModal}>
                    <ClearIcon />
                  </button>
                </div>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: 700,
                    fontFamily: 'Roboto',
                    marginBottom: 22,
                    marginTop: 0,
                  }}
                >
                  Lastnosti modela
                </p>
                <pre className="settings_show_scrollbar">
                  {JSON.stringify(modelMetadata, null, 2)}
                </pre>
              </>
            )) || (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                }}
              >
                <p>Podatki za model trenutno niso na voljo</p>
                <button type="button" onClick={closeModelInfoModal}>
                  <ClearIcon />
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsDrawer;
