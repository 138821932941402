import {
  IRefectTokens,
  ISnack,
  IStore,
  IUser,
  UserRoleEnums,
} from '../store/IStore';
import { IAction } from '../actions/IActions';
import { combineReducers } from 'redux';
import {
  SET_DO_DICTATION,
  SET_MAKE_BLOCKS,
  SET_SAVE_TO_DISK,
  SET_CONFIG,
  SET_SNACK,
  SET_REFETCH_TOKENS,
  SET_USER,
  SET_DELAY,
  SET_UPLOAD_IN_LIVE_MODE,
  SET_AUDIO_CHUNK_SIZE,
  SET_STT_STATUS,
} from '../actions/actions';
import { IConfig, ISttStatus } from '../../components/Main/ISettings';

const initialState: IStore = {
  refetchTokens: { refetchCount: 0, refetchTime: null },
  doDictation: false,
  makeBlocks: false,
  saveToDisk: false,
  user: null,
  config: null,
  snack: {
    open: false,
    message: '',
    variant: 'success',
  },
  delay: 3000,
  uploadInLiveMode: true,
  audioChunkSize: 4096,
  sttStatus: null,
};

const audioChunkSize = (
  state: number = initialState.audioChunkSize,
  action: IAction
) => {
  switch (action.type) {
    case SET_AUDIO_CHUNK_SIZE:
      return action.audioChunkSize;
    default:
      return state;
  }
};

const uploadInLiveMode = (
  state: boolean = initialState.uploadInLiveMode,
  action: IAction
) => {
  switch (action.type) {
    case SET_UPLOAD_IN_LIVE_MODE:
      return action.uploadInLiveMode;
    default:
      return state;
  }
};

const delay = (state: number = initialState.delay, action: IAction) => {
  switch (action.type) {
    case SET_DELAY:
      return action.delay;
    default:
      return state;
  }
};

const refetchTokens = (
  state: IRefectTokens = initialState.refetchTokens,
  action: IAction
) => {
  switch (action.type) {
    case SET_REFETCH_TOKENS:
      // return { ...state, ...action.audioInfo };
      return {
        refetchCount: state.refetchCount + 1,
        refetchTime: action.refetchTokens,
      };
    default:
      return state;
  }
};

const doDictation = (
  state: boolean = initialState.doDictation,
  action: IAction
) => {
  switch (action.type) {
    case SET_DO_DICTATION:
      return action.doDictation;
    default:
      return state;
  }
};

const makeBlocks = (
  state: boolean = initialState.makeBlocks,
  action: IAction
) => {
  switch (action.type) {
    case SET_MAKE_BLOCKS:
      return action.makeBlocks;
    default:
      return state;
  }
};

const saveToDisk = (
  state: boolean = initialState.saveToDisk,
  action: IAction
) => {
  switch (action.type) {
    case SET_SAVE_TO_DISK:
      return action.saveToDisk;
    default:
      return state;
  }
};

const user = (state: IUser | null = initialState.user, action: IAction) => {
  switch (action.type) {
    case SET_USER:
      return !action.user
        ? null
        : {
            ...action.user,
            isAdmin: action.user.userRoles.includes(UserRoleEnums.ADMIN),
          };
    default:
      return state;
  }
};

const config = (
  state: IConfig | null = initialState.config,
  action: IAction
) => {
  switch (action.type) {
    case SET_CONFIG:
      return action.config;
    default:
      return state;
  }
};

const sttStatus = (
  state: ISttStatus | null = initialState.sttStatus,
  action: IAction
) => {
  switch (action.type) {
    case SET_STT_STATUS:
      return action.sttStatus;
    default:
      return state;
  }
};

const snack = (state: ISnack = initialState.snack, action: IAction) => {
  switch (action.type) {
    case SET_SNACK:
      return action.snack;
    default:
      return state;
  }
};

const app = combineReducers({
  doDictation,
  makeBlocks,
  saveToDisk,
  user,
  snack,
  refetchTokens,
  config,
  sttStatus,
  delay,
  uploadInLiveMode,
  audioChunkSize,
});

export default app;
