import axios from 'axios';

const getRtvApiUrl = (channel: string) => {
  if (window.location.hostname === 'localhost') {
    return `https://api.rtvslo.si/ava/getLiveStream/tv.${channel.toLocaleLowerCase()}?&client_id=82013fb3a531d5414f478747c1aca622`;
  } else {
    // Due to CORS policy on api.rtvslo.si, we have to proxy through backend.
    // See /nginx/nginx.conf file.
    return `/api/rtv/${channel.toLocaleLowerCase()}`;
  }
};

export const getStreamingUrl = async (channel: string) => {
  // First, call rtv api to get the valid token for live streaming
  const url = getRtvApiUrl(channel);
  try {
    const r = await axios.get(url);
    // Response should be like:
    // {
    //   "response": {
    //     "title": "TV Slovenija 3",
    //     "source": "SLO3",
    //     ...
    //     "mediaFiles": [{
    //       "dvr": 7200,
    //       "sub": false,
    //       "ip": "86.58.78.152",
    //       "geo": {
    //         "country_code": "SI",
    //         "status": "success"
    //       },
    //       "cc": "SI",
    //       "file": <PATH_TO_FILE_WITH_TOKEN>
    //       "mediaType": "video",
    //       "streamer": <CDN_URL>
    //       "type": "hls"
    //     }],
    //     ...
    // }
    //
    // We are interested in `file` and `streamer` properties. `streamer` is CDN url and `file` is url relative path with token.
    // For example:
    //
    //   streamer = 'https://cdn-live.rtvslo.si/hls/live/2095063/slo3_ott'
    //   file = '/playlist.m3u8?hdnts=exp=1666695580~acl=*~hmac=90632ddd0cd571980bd67ad80ec3e7fce4f8138c9c74a680fed647098f50ba0c'
    //
    // and together they are valid streaming url (for one hour).
    const [mediaFile] = r.data.response.mediaFiles;
    const { streamer, file } = mediaFile;

    return `${streamer}${file}`;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
