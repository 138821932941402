import { debounce } from 'lodash';
import { useCallback, useEffect, useState, useRef } from 'react';
// import debounce from 'lodash.debounce';

export function useDebouncedCallback(
  callback: any,
  delay: any = 0,
  options: any = []
) {
  return useCallback(debounce(callback, delay, options), [
    callback,
    delay,
    ...options,
  ]);
}

export function useDebounce(value: any, delay: any, options: any) {
  const previousValue = useRef(value);
  const [current, setCurrent] = useState(value);
  const debouncedCallback = useDebouncedCallback(
    () => setCurrent(value),
    delay,
    options
  );
  useEffect(() => {
    // does trigger the debounce timer initially
    if (value !== previousValue.current) {
      debouncedCallback(value);
      previousValue.current = value;
      // cancel the debounced callback on clean up
      return debouncedCallback.cancel;
    }
  }, [value]);

  return current;
}
