// CommonAudio.js 0.1.0
// 2019-12-19

//Aliases
window.AudioContext = window.AudioContext || window.webkitAudioContext;
window.OfflineAudioContext = window.OfflineAudioContext || window.webkitOfflineAudioContext;
AudioContext.prototype.createScriptProcessor = AudioContext.prototype.createScriptProcessor || AudioContext.prototype.createJavaScriptNode;

/** Converts Float32 array to 16 bit little endian integer BufferArray */
export function floatTo16BitPCM(input) {

    //Each 32bit (4byte) float from input is converted to one 16bit (2byte) integer.
    //Each element needs 2 bytes
    let buffer = new ArrayBuffer(input.length * 2);

    //Define view to raw buffer so we can set values as int16.
    let view = new DataView(buffer);

    for (let i = 0; i < input.length; i++) {
        //Limit input to [-1, -1]
        const s = Math.max(-1, Math.min(1, input[i]));

        //Convert float32 to int16 and force little endian
        view.setInt16(2 * i, s < 0 ? s * 0x8000 : s * 0x7fff, true);
    }

    return buffer;
}

/** Downmix multiple channel audio to mono with offlineAudioContext. Max have problems on safari because OfflineAudioContext! */
export async function downmixToMono(audioBuffer) {
    let offlineAudioContext = new window.OfflineAudioContext(1, audioBuffer.length, audioBuffer.sampleRate);

    let bufferSource = offlineAudioContext.createBufferSource();
    bufferSource.buffer = audioBuffer;
    bufferSource.start(0);
    bufferSource.connect(offlineAudioContext.destination)

    return await offlineAudioContext.startRendering();
}

/** Wrapper for file reader to support await async scheme */
export function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
    })
}

/** Resamples asudio to desired sample rate. May have problems on Safari because OfflineAudioContext! */
export async function resampleAudio (audioData, targetSampleRate) {

    //Calculate size of data after re-sampling
    let resampledSize = Math.ceil (( targetSampleRate / audioData.sampleRate ) * audioData.length );

    //Create OfflineAudioContext which allow us to re-sample audio stored in buffer
    let offlineAudioContext= new window.OfflineAudioContext( 1, resampledSize, targetSampleRate );

    let sourceNode = offlineAudioContext.createBufferSource();
    sourceNode.buffer = audioData;
    sourceNode.connect(offlineAudioContext.destination);
    sourceNode.start(0);

    return await offlineAudioContext.startRendering();
}