import React, { useState } from 'react';
import './main.css';

import { TvSlo1 } from '../Icons/TvSlo1';
import { TvSlo2 } from '../Icons/TvSlo2';
import { TvSlo3 } from '../Icons/TvSlo3';
import { UploadIcon } from '../Icons/UploadIcon';
import { StreamIcon } from '../Icons/StreamIcon';
import { DownArrow } from '../Icons/DownArrow';
import { Channels, IChannel } from './IMain';

interface IProps {
  channelsOpened: boolean;
  activeChannelHovered: boolean;
  activeChannel: Channels | undefined;
  setActiveChannelHovered: (value: boolean) => void;
  setChannelsOpened: (value: boolean) => void;
  setChosenChannelHovered: (value: number | null) => void;
  setUploadHovered: (value: boolean) => void;
  handleChannelClick: (channel: IChannel) => void;
  handleVideoSelect: (event: any) => void;
  videoIsFullScreen: boolean;
  chosenChannelHovered: number | null;
  uploadHovered: boolean;
  channels: IChannel[];
  showVideoPlayer: boolean;
  handleOpenSettings: () => void;
}

const Menu = ({
  channelsOpened,
  setActiveChannelHovered,
  videoIsFullScreen,
  activeChannelHovered,
  activeChannel,
  setChannelsOpened,
  handleChannelClick,
  setChosenChannelHovered,
  chosenChannelHovered,
  setUploadHovered,
  handleVideoSelect,
  uploadHovered,
  channels,
  showVideoPlayer,
  handleOpenSettings,
}: IProps) => {

  return (
    <div
      className="channels_wrapper"
      style={{
        backgroundColor: channelsOpened
          ? 'rgba(112, 112, 112, 0.5)'
          : 'transparent',
      }}
      onMouseLeave={() => {
        showVideoPlayer && setChannelsOpened(false);
        setActiveChannelHovered(false);
      }}
    >
      {!channelsOpened ? (
        <div
          className="active_channel_button"
          onMouseEnter={() =>  setActiveChannelHovered(true)}
          onMouseLeave={() =>  setActiveChannelHovered(false)}
          style={{ paddingTop: videoIsFullScreen ? '5px' : '0px' }}
        >
          {activeChannelHovered && (
            <div className="active_channel_overlay"></div>
          )}
          {activeChannel === Channels.SLO1 ? (
            <TvSlo1 hoverActive={activeChannelHovered || videoIsFullScreen} />
          ) : activeChannel === Channels.SLO2 ? (
            <TvSlo2 hoverActive={activeChannelHovered || videoIsFullScreen} />
          ) : activeChannel === Channels.SLO3 ? (
            <TvSlo3 hoverActive={activeChannelHovered || videoIsFullScreen} />
          ) : (
            <StreamIcon hoverActive={activeChannelHovered || videoIsFullScreen} />
          )}
          {activeChannelHovered && (
            <div
              className="open_channels_button"
              onClick={() => setChannelsOpened(true)}
            >
              <DownArrow height="15px" width="15px" />
            </div>
          )}
        </div>
      ) : (
        <>
          {channels.map((channel, i) => (
            <div
              style={{
                paddingTop: videoIsFullScreen ? '5px' : '0px',
                cursor: 'pointer',
                /*cursor:
                  channel.label === Channels.SLO1 ||
                  channel.label === Channels.SLO2
                    ? "not-allowed"
                    : "pointer",*/
              }}
              className="channel_button_wrapper"
              key={i}
              onClick={() => {
                /*!(
                  channel.label === Channels.SLO1 ||
                  channel.label === Channels.SLO2
                ) &&*/ handleChannelClick(channel);
              }}
              onMouseEnter={() => setChosenChannelHovered(i)}
              onMouseLeave={() => setChosenChannelHovered(null)}
            >
              <div
                className="channel_button_overlay"
                style={
                  {
                    /*opacity:
                    channel.label === Channels.SLO1 ||
                    channel.label === Channels.SLO2
                      ? "0.3"
                      : "1",*/
                  }
                }
              >
                {channel.label === Channels.SLO1 ? (
                  <TvSlo1 hoverActive hover={chosenChannelHovered === i} />
                ) : channel.label === Channels.SLO2 ? (
                  <TvSlo2 hoverActive hover={chosenChannelHovered === i} />
                ) : channel.label === Channels.SLO3 ? (
                  <TvSlo3 hoverActive hover={chosenChannelHovered === i} />
                ) : (
                  <StreamIcon hoverActive hover={chosenChannelHovered === i} />
                )}
              </div>
            </div>
          ))}
        </>
      )}

      <div
        className={
          channelsOpened
            ? 'channel_button_wrapper'
            : 'channel_button_wrapper_hidden'
        }
        onMouseEnter={() => setUploadHovered(true)}
        onMouseLeave={() => setUploadHovered(false)}
      >
        <div className="channel_button_overlay">
          <input
            type="file"
            name="video"
            id="file"
            accept="video/mp4"
            className="input-file"
            onChange={handleVideoSelect}
          />
          <label htmlFor="file">
            {' '}
            <UploadIcon hover={uploadHovered} />
          </label>
        </div>
      </div>

      {/*<div
        className={
          channelsOpened
            ? 'channel_button_wrapper'
            : 'channel_button_wrapper_hidden'
        }
        onMouseEnter={() => setStreamHoveredSecond(true)}
        onMouseLeave={() => setStreamHoveredSecond(false)}
        onClick={showStreamUrlModal}
      >
        <div className="channel_button_overlay">
          <StreamIconSecond hover={streamHoveredSecond} />
        </div>
      </div>
      */}

      <div className="settings_button_container">
        <div className="settings_button" onClick={handleOpenSettings}>
          NASTAVITVE
        </div>
      </div>
    </div>
  );
};

export default Menu;
