import React, { useEffect, useRef, useState } from 'react';
import './main.css';
import { processAudioFile } from '../WebSock/processAudioFile';
import {
  Channels,
  IChannel,
  ILiveSubtitles,
  IReceived,
  IReceivedTranscript,
  ISubtitle,
  ITranscriptSegment,
  ITranscriptWord,
  ReceivedMessageTypes,
} from './IMain';
import trContentToSegment from '../../shared/trContentToSegment';
import videojs, { VideoJsPlayer } from 'video.js';
import Menu from './Menu';
import Subtitles from './Subtitles';
import { FullScreenIcon } from '../Icons/FullScreen';
import { MinimizeIcon } from '../Icons/MinimizeScreen';
import { readFileAsync } from '../Libs/CommonAudio';
import useWebsocket from '../WebSock/useWebsocket';
import { Microphone } from '../Libs/Microphone';
import SettingsDrawer from './SettingsDrawer';
import { useSelector } from 'react-redux';
import { IStore } from '../../redux/store/IStore';
import joinPunctuations from '../../shared/joinPunctuations';
import useSpeakerChange from '../../hooks/useSpeakerChange';
import generateUploadSubtitles from '../../shared/generateUploadSubtitles';
import generateLiveSubtitles from '../../shared/generateLiveSubtitles';
import { getStreamingUrl } from '../../api/rtv';
import TextField from '@mui/material/TextField';
import { ClearIcon } from '../Icons/ClearIcon';
import { useSnackbar } from 'notistack';

export enum FlowModes {
  LIVE = 'LIVE',
  UPLOAD = 'UPLOAD',
}

const SAMPLE_RATE = 16000;
const CHUNK_SIZE = 4096;

interface el extends HTMLElement {
  mozRequestFullScreen: () => void;
  exitFullscreen: () => void;
  mozCancelFullScreen: () => void;
  webkitExitFullscreen: () => void;
  fullscreenElement: () => void;
  mozFullScreenElement: () => void;
  webkitFullscreenElement: () => void;
  webkitRequestFullscreen: any;
  msExitFullscreen: any;
}

interface docu extends Document {
  mozRequestFullScreen: any;
  exitFullscreen: any;
  mozCancelFullScreen: any;
  webkitExitFullscreen: any;
  fullscreenElement: any;
  mozFullScreenElement: any;
  webkitFullscreenElement: any;
  webkitRequestFullscreen: any;
  msExitFullscreen: any;
}

const channels: IChannel[] = Object.values(Channels).map((c) => ({ label: c }));

const waitingSubtitle: ISubtitle = {
  firstRow: 'Čakam na podnapise...',
  secondRow: null,
  startTime: null,
  endTime: null,
  numberOfWords: 3,
  firstRowConfidence: [],
  secondRowConfidence: [],
};

const Main = () => {
  const [isMounted, setIsMounted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  //Stream modal state
  const streamInputRef = useRef<HTMLInputElement | null>(null);
  const [streamUrlModalVisible, setStreamUrlModalVisible] = useState<boolean>(false);
  const showStreamUrlModal = () => setStreamUrlModalVisible(true)
  const hideStreamUrlModal = () => setStreamUrlModalVisible(false)
  
  const streamUrlRef = useRef<string | null>(null);

  // Upload mode state
  const [selectedVideoFile, setSelectedVideoFile] = useState<File>();
  const [uploadSubtitles, setUploadSubtitles] = useState<ISubtitle[]>([]);
  const [shownSubtitle, setShownSubtitle] = useState<ISubtitle | null>(null);

  // Live mode state
  const [streamChannel, setStreamChannel] = useState<IChannel>();
  const [channelsOpened, setChannelsOpened] = useState<boolean>(true);
  const [activeChannel, setActiveChannel] = useState<Channels>();
  const [activeChannelHovered, setActiveChannelHovered] =
    useState<boolean>(false);
  const [chosenChannelHovered, setChosenChannelHovered] = useState<
    number | null
  >(null);
  const [liveSubtitles, setLiveSubtitles] = useState<ILiveSubtitles>({
    staticRow: [],
    bufferRow: [],
  });

  // General state
  const [videoPlayer, setVideoPlayer] = useState<VideoJsPlayer>();
  const [showVideoPlayer, setShowVideoPlayer] = useState<boolean>(false);
  const [currentTime, setCurrentTime] = useState<number>();
  const [showConfidence, setShowConfidence] = useState<boolean>(false);
  const [subtitleFontSize, setSubtitleFontSize] = useState<number>(42);
  const [videoIsFullScreen, setVideoIsFullScreen] = useState<boolean>(false);
  const [uploadHovered, setUploadHovered] = useState<boolean>(false);
  
  const [showCommands, setShowCommands] = useState<boolean>(false);
  const [showFullScreenCommand, setShowFullScreenCommand] =
    useState<boolean>(false);
  const [screenChangeHovered, setScreenChangeHovered] =
    useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [flowMode, setFlowMode] = useState<FlowModes>();

  const { applySpeakerChanges } = useSpeakerChange();

  const videoRef = useRef<HTMLVideoElement>(null);

  const user = useSelector((store: IStore) => store.user);
  const delay = useSelector((store: IStore) => store.delay);
  const uploadInLiveMode = useSelector(
    (store: IStore) => store.uploadInLiveMode
  );
  const audioChunkSize = useSelector((store: IStore) => store.audioChunkSize);

  const microphone = useRef<Microphone>(
    new Microphone({
      sampleRate: SAMPLE_RATE,
      chunkSize: CHUNK_SIZE,
    })
  );

  const [videoElementKey, setVideoElementKey] = useState<number>(1);

  useEffect(() => {
    // axios.get("https://true-bar.si:8082/api/about").then((data: any) => {console.log(data.data)});

    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (!isMounted) return;
    if (!user) {
      setShowSettings(true);
    }
  }, [user, isMounted]);

  const onMessage = (message: IReceived) => {
    // Transcript
    if (message.messageType === ReceivedMessageTypes.TRANSCRIPT) {
      const castedMessage = message as IReceivedTranscript;

      let trContent: ITranscriptWord[] = JSON.parse(
        castedMessage.transcript.content as string
      );

      console.log(trContent);

      // Join punctuations to previous words
      trContent = joinPunctuations(trContent);

      // Add speaker delimiters
      if (castedMessage.isFinal) {
        trContent = applySpeakerChanges(trContent);
      }

      // Video was uploaded
      if (flowMode === FlowModes.UPLOAD && castedMessage.isFinal) {
        const transcriptSegment: ITranscriptSegment[] = trContentToSegment(
          trContent,
          true
        );
        const newSubtitles: ISubtitle[] =
          generateUploadSubtitles(transcriptSegment);
        setUploadSubtitles((uploadSubtitles) => [
          ...uploadSubtitles,
          ...newSubtitles,
        ]);
      }

      // Channel is being streamed
      else if (flowMode === FlowModes.LIVE) {
        setLiveSubtitles((liveSubtitles: ILiveSubtitles) =>
          generateLiveSubtitles(liveSubtitles, trContent, castedMessage.isFinal)
        );
      }
    }
  };

  const { openWebsocket, closeWebsocket, sendMessage } =
    useWebsocket(onMessage);

  // Autoplay
  /*useEffect(() => {
    setChannelsOpened(false);
    setActiveChannel(Channels.SLO3);
    setStreamChannel(channels[2]);
  }, []);*/

  /** LIVE FLOW */

  // TV Channel click listener
  // Clean up the state and select new channel
  const handleChannelClick = async (channel: IChannel) => {
    // Change channel if clicked on a new channel
    streamUrlRef.current = null
    if (!streamChannel || streamChannel.label !== channel.label || channel.label === Channels.STREAM) {
      unloadMicrophone();

      await closeWebsocket();

      if (channel.label === Channels.STREAM) {
        showStreamUrlModal();
        
      } else {
        setShowVideoPlayer(true);
        setSelectedVideoFile(undefined);
        setChannelsOpened(false);
        setActiveChannel(channel.label);
        setLiveSubtitles({ staticRow: [], bufferRow: [] });
        setStreamChannel(channel);
        setFlowMode(FlowModes.LIVE);
  
        if (videoPlayer) {
          videoPlayer.play();
        }
      }
    }
  };



  // When stream channel is selected play the video and start streaming its audio
  // NOTE: This use effect gets streaming url
  useEffect(() => {
    const fetchUrlAndPlay = async () => {
      if (streamChannel && videoPlayer) {
        if (streamChannel.label === Channels.STREAM) {
          if (streamUrlRef.current) {
            videoPlayer.src({
              type: 'application/x-mpegURL',
              src: streamUrlRef.current
            });
  
            videoPlayer.play();
            streamAudio();
            return;
          }
        } else {
          const channelUrl = await getStreamingUrl(streamChannel.label);
  
          console.log("channelUrl")
          console.log(channelUrl)
          if (channelUrl) {
            videoPlayer.src({
              type: 'application/x-mpegURL',
              src: channelUrl,
            });
            videoPlayer.play();
          }
  
          streamAudio();
        }
      }
    };

    fetchUrlAndPlay();
  }, [streamChannel, videoPlayer]);

  // Stream selected channel audio via ws
  const streamAudio = async () => {
    if (videoRef.current) {
      // Open websocket
      await openWebsocket();

      // Add callback to send chunks
      microphone.current.registerListener((chunk: ArrayBuffer) => {
        sendMessage(chunk);
      });

      // Start new recording
      await microphone.current.start(videoRef.current);
    }
  };

  const unloadMicrophone = (stop: boolean = false) => {
    if (!!microphone.current) {
      microphone.current.unregisterListener();
      if (stop) {
        // This will also disassociate audio processor from the video element,
        // so the audio procoessor cannot resume. To start playing again,
        // the 'Microphone' object has to be recreated again.
        microphone.current.stop();
      }
    }
  };

  /** UPLOAD FLOW */

  // Upload button click listener
  // Clean up the state and select new channel
  const handleVideoSelect = async (event: any) => {
    const file: File = event.target.files[0];

    unloadMicrophone();

    await closeWebsocket();

    setLiveSubtitles({ staticRow: [], bufferRow: [] });
    setStreamChannel(undefined);
    setSelectedVideoFile(file);
    streamUrlRef.current = null

    event.target.value = '';
  };

  // When video file is selected play the video and upload it via ws
  useEffect(() => {
    if (selectedVideoFile) {
      // Load video to player
      if (videoPlayer) {
        videoPlayer.src({
          type: 'video/mp4',
          src: URL.createObjectURL(selectedVideoFile),
        });

        setShowVideoPlayer(true);

        // Simulate live flow
        if (uploadInLiveMode) {
          setFlowMode(FlowModes.LIVE);

          videoPlayer.play();

          streamAudio();
        }

        // Normal upload flow
        else {
          setFlowMode(FlowModes.UPLOAD);

          // Upload file
          uploadFile(selectedVideoFile);
        }
      }
    }
  }, [selectedVideoFile]);

  // Upload selected file via ws - not tested yet
  const uploadFile = async (file: File) => {
    // First open websocket if not open yet
    await openWebsocket();

    // Read file
    const fileArrayBuffer = await readFileAsync(file);

    await processAudioFile(
      fileArrayBuffer,
      1,
      SAMPLE_RATE,
      audioChunkSize,
      delay / 1000,
      onAudioDecoded,
      (chunk: ArrayBuffer) => sendMessage(chunk)
    );
  };

  // Delay video playing by the delay
  // You have to do that here beacuse only now the data is ready to be sent to the server
  const onAudioDecoded = () => {
    setTimeout(() => {
      if (videoPlayer) {
        videoPlayer.play();
      }
    }, delay);
  };

  /** GENERAL LOGIC */

  useEffect(() => {
    if (channelsOpened) {
      setActiveChannelHovered(false);
    }
  }, [channelsOpened]);

  // Chooses the correct subtitle
  useEffect(() => {
    if (currentTime && currentTime > 0) {
      let subtitleExists = false;

      // Iterate through following uploadSubtitles
      for (let i = 0; i < uploadSubtitles.length; i++) {
        const subtitle = uploadSubtitles[i];

        // Go thorough all prev uploadSubtitles
        if (subtitle.endTime && currentTime > subtitle.endTime) {
          continue;
        }

        // currentTime is either inside or before the current subtitle - we know the subtitle exists
        subtitleExists = true;

        // Here we are before the subtitle (remove shown subtitle)
        if (subtitle.startTime && currentTime < subtitle.startTime) {
          setShownSubtitle(null);
          break;
        }

        // Here we are inside the subtitle (show the current subtitle)
        if (
          subtitle.startTime &&
          subtitle.endTime &&
          currentTime <= subtitle.endTime &&
          currentTime >= subtitle.startTime
        ) {
          setShownSubtitle(subtitle);
          break;
        }
      }

      if (!subtitleExists) {
        setShownSubtitle(null);
      }
    }
  }, [currentTime]);

  // Attach listener to update currentTime
  // NOTE: This useEffect sets video player
  useEffect(() => {
    if (videoRef.current) {
      unloadMicrophone(true);

      microphone.current = new Microphone({
        sampleRate: SAMPLE_RATE,
        chunkSize: audioChunkSize,
      });

      const video = videoRef.current as unknown as HTMLVideoElement;

      video.addEventListener('timeupdate', () => {
        setCurrentTime(video.currentTime);
      });

      // Initialize videojs
      console.log("CREATING")
      const videojsPlayer = videojs(videoRef.current, {
        controls: false,
        autoplay: false,
        preload: 'auto',
        html5: {
          vhs: {
            overrideNative: !videojs.browser.IS_SAFARI
          }
        }
      });
      setVideoPlayer(videojsPlayer);

    }
  }, [videoElementKey, audioChunkSize]);

  // Handle fullscreen logic
  useEffect(() => {
    document.addEventListener('fullscreenchange', (event) => {
      if (document.fullscreenElement) {
        setVideoIsFullScreen(true);
        setShowCommands(false);
        setShowFullScreenCommand(false);
      } else {
        setVideoIsFullScreen(false);
      }
    });

    return () =>
      document.removeEventListener('fullscreenchange', (event) => {});
  }, []);

  const increaseFontSize = () => {
    setSubtitleFontSize(subtitleFontSize + 2);
  };

  const decreaseFontSize = () => {
    setSubtitleFontSize(subtitleFontSize - 2);
  };

  const toggleConfidence = () => {
    setShowConfidence(!showConfidence);
  };

  const openFullscreen = () => {
    const mainContainer = document.getElementsByClassName('main_container')[0];
    if (mainContainer.requestFullscreen) {
      mainContainer.requestFullscreen();
      setVideoIsFullScreen(true);
    } else if ((mainContainer as el).mozRequestFullScreen) {
      /* Firefox */
      (mainContainer as el).mozRequestFullScreen();
      setVideoIsFullScreen(true);
    } else if ((mainContainer as el).webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      (mainContainer as el).webkitRequestFullscreen();
      setVideoIsFullScreen(true);
    }
  };

  const closeFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
      setVideoIsFullScreen(false);
    } else if ((document as docu).mozCancelFullScreen) {
      (document as docu).mozCancelFullScreen();
      setVideoIsFullScreen(false);
    } else if ((document as docu).webkitExitFullscreen) {
      (document as docu).webkitExitFullscreen();
      setVideoIsFullScreen(false);
    } else if ((document as docu).msExitFullscreen) {
      (document as docu).msExitFullscreen();
      setVideoIsFullScreen(false);
    }
  };

  const pauseStream = () => {
    if (!!videoPlayer) {
      unloadMicrophone();

      closeWebsocket();

      videoPlayer.pause();
    }
    return false;
  };

  const resumeStream = () => {
    if (!!videoPlayer && FlowModes.LIVE === flowMode) {
      setStreamChannel(streamChannel);

      videoPlayer.play();
      streamAudio();
    }
  };

  const togglePlay = () => {
    if (!!videoPlayer) {
      if (videoPlayer.paused()) {
        resumeStream();
      } else {
        pauseStream();
      }
    }
  };

  const handleOpenSettings = () => {
    pauseStream();
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
    setVideoElementKey((prevValue) => prevValue + 1);
    resumeStream();
  };

  const preventOnClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  const confirmM3U8Link = async () => {
    if (!streamInputRef.current) return;

    if (!streamInputRef.current.value.endsWith(".m3u8")) {
      enqueueSnackbar(
        "Vnesli ste napačno povezavo. Datoteka se mora zaključiti z .m3u8",
        {
          variant: 'error',
        }
      );
      return;
    }

    let src = ``
    if (streamInputRef.current.value.startsWith("https://")) {
      src = `api/service_provider/secure/${streamInputRef.current.value.substring(8)}`
    } else if (streamInputRef.current.value.startsWith("http://")) {
      src = `api/service_provider/insecure/${streamInputRef.current.value.substring(7)}`
    } else {
      enqueueSnackbar(
        "Povezava se mora začeti s http:// ali https://",
        { variant: 'error', }
      );
      return;
    }

    streamUrlRef.current = src
    setShowVideoPlayer(true);
    setSelectedVideoFile(undefined);
    setChannelsOpened(false);
    setActiveChannel(Channels.STREAM);
    setLiveSubtitles({ staticRow: [], bufferRow: [] });
    setStreamChannel({ label: Channels.STREAM });
    setFlowMode(FlowModes.LIVE);
    hideStreamUrlModal();
  }

  return (
    <>
      <SettingsDrawer
        handleCloseSettings={handleCloseSettings}
        show={showSettings}
      />

      <div className="main_container">
        <Menu
          channelsOpened={channelsOpened}
          activeChannelHovered={activeChannelHovered}
          activeChannel={activeChannel}
          setActiveChannelHovered={setActiveChannelHovered}
          setChannelsOpened={setChannelsOpened}
          setChosenChannelHovered={setChosenChannelHovered}
          setUploadHovered={setUploadHovered}
          handleChannelClick={handleChannelClick}
          handleVideoSelect={handleVideoSelect}
          videoIsFullScreen={videoIsFullScreen}
          chosenChannelHovered={chosenChannelHovered}
          uploadHovered={uploadHovered}
          channels={channels}
          showVideoPlayer={showVideoPlayer}
          handleOpenSettings={handleOpenSettings}
        />

        <div
          className={
            videoIsFullScreen ? 'video_wrapper fullscreen' : 'video_wrapper'
          }
          style={{ visibility: showVideoPlayer ? 'visible' : 'hidden' }}
        >
          <div
            onMouseEnter={() => setShowFullScreenCommand(true)}
            onMouseLeave={() => setShowFullScreenCommand(false)}
            className={
              showFullScreenCommand
                ? 'fullscreen_command_wrapper show'
                : 'fullscreen_command_wrapper'
            }
          >
            <div className="fs_command_holder">
              <span className="live_text">LIVE</span>{' '}
            </div>

            <div className="fs_command_holder">
              <div
                className="fullscree_icon_wrapper"
                onMouseEnter={() => setScreenChangeHovered(true)}
                onMouseLeave={() => setScreenChangeHovered(false)}
                onClick={!videoIsFullScreen ? openFullscreen : closeFullscreen}
              >
                {videoIsFullScreen ? (
                  <MinimizeIcon hover={screenChangeHovered} />
                ) : (
                  <FullScreenIcon hover={screenChangeHovered} />
                )}
                <span>
                  {videoIsFullScreen ? 'OBIČAJNI NAČIN' : 'CELOZASLONSKI NAČIN'}
                </span>{' '}
              </div>
            </div>
          </div>

          <div
            key={videoElementKey}
            className={
              videoIsFullScreen ? 'inner_wrapper fullscreen' : 'inner_wrapper'
            }
          >
            <video
              id="my-player"
              className={videoIsFullScreen ? 'video-js fullscreen' : 'video-js'}
              ref={videoRef}
              onClick={togglePlay}
            >
              Your browser does not support the video tag.
            </video>
          </div>

          <Subtitles
            flowMode={flowMode}
            liveSubtitles={liveSubtitles}
            showCommands={showCommands}
            videoIsFullScreen={videoIsFullScreen}
            showConfidence={showConfidence}
            subtitleFontSize={subtitleFontSize}
            shownSubtitle={shownSubtitle}
            toggleConfidence={toggleConfidence}
            decreaseFontSize={decreaseFontSize}
            increaseFontSize={increaseFontSize}
            setShowCommands={setShowCommands}
          />
        </div>

        <div
          className={
            videoIsFullScreen ? 'bottom_info fullscreen' : 'bottom_info'
          }
        >
          {!showVideoPlayer && (
            <h3 className="select_channel_text">PROSIMO IZBERITE PROGRAM</h3>
          )}

          {/*
            <p>
              {" "}
              © <b>Vitasis</b> & <b>Laboratorij za podatkovne tehnologije</b>,
              UL-FRI
            </p>
          */}
        </div>
      </div>

      {streamUrlModalVisible && (
        <div
            onClick={hideStreamUrlModal}
            style={{
              zIndex: 35,
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#0000001A',
            }}
          >
            <div
              onClick={preventOnClick}
              style={{
                paddingLeft: 39,
                paddingTop: 33,
                paddingBottom: 37,
                paddingRight: 44,
                width: '40%',
                backgroundColor: '#F2F2F2',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <p className="m3u8-title">Vnesite m3u8 povezavo</p>
                <button type="button" onClick={hideStreamUrlModal}>
                  <ClearIcon />
                </button>
              </div>
              <TextField inputRef={streamInputRef} fullWidth className='m3u8-textfield' placeholder='Primer: https://host/playlist.m3u8' label="Povezava" variant="outlined" />
              <div className='m3u8-confirm-button-container'>
                <button className='m3u8-confirm-button' type="button" onClick={confirmM3U8Link}>
                  POTRDI
                </button>
              </div>
            </div>
          </div>
      )}
    </>
  );
};

export default Main;