export const API = 'api';
export const COMMON = 'common';
export const INFO = 'info';
export const LOGS = 'logs';
export const DICTIONARY = 'dictionary';
export const WORDS = 'words';
export const PRONUNCIATIONS = 'pronunciations';
export const TYPES = 'types';
export const FREQUENCY_CLASSES = 'frequencyClasses';
export const DICTATION = 'dictation';
export const SUBSTITUTIONS = 'substitutions';
export const COMMANDS = 'commands';
export const AUTHENTICATE = 'authenticate';
export const CLIENT = 'client';
export const EDITOR = 'editor';
export const TRANSCRIPTS = 'transcripts';
export const TRANSCRIPTS_BATCH = 'transcripts-batch';
export const CONTENT = 'content';
export const REPLACE_CONTENT = 'replace-content';
export const LANGUAGES = 'languages';
export const DOMAINS = 'domains';
export const DMODELS = 'dmodels';
export const CONFIG = 'configuration';
export const SESSIONS = 'sessions';
export const TRANSCODE = 'transcode';
export const CONVERT = 'convert';
export const RECORDINGS = 'recordings';
export const EDITOR_CONTENT = 'editor-content';
export const REPLACEMENTS = 'replacements';
export const LABELS = 'labels';
export const COPY = 'copy';
export const SUMMARY = 'summary';
export const SHARES = 'shares';
export const USERS = 'users';
export const STT = 'stt';
export const STATUS = 'status';

// https://dev-editor-backend.true-bar.si/swagger-ui/index.html?configUrl=/v3/api-docs/swagger-config#/editor-api-controller/getSessionTranscripts
