import React from "react";

export function UploadIcon(props: any) {
  const bgColor = props.hover ? "#2B8DFF" : "rgba(37,37,37,0.5)";
  const channelNameColor = props.hoverActive
    ? "rgb(237, 238, 239)"
    : "rgba(37, 37, 37, 0.5)";
  return (
    <>
      <svg
        id="upload"
        xmlns="http://www.w3.org/2000/svg"
        width="110.809"
        height="114.2"
        viewBox="0 0 110.809 114.2"
      >
        <g id="g31701" transform="translate(0 -16.745)">
          <path
            id="path4813"
            d="M12573.531-15361.229h-80.937v-114.2h80.938Z"
            transform="translate(-12492.594 15492.174)"
            fill={bgColor}
          />
          <path
            id="path4827"
            d="M12724.986-15171.4h13.037v-13.084h-13.037Z"
            transform="translate(-12630.865 15302.323)"
            fill="#edeeef"
          />
          <path
            id="Path_59"
            data-name="Path 59"
            d="M54.386,21.806H40.276V3H19.11V21.806H5L29.693,43.747ZM5,50.016v6.269H54.386V50.016Z"
            transform="translate(85.895 133.931) rotate(180)"
            fill="#edeeef"
          />
          <text
            id="UPLOAD-2"
            data-name="UPLOAD"
            transform="translate(106.809 111.747) rotate(-90)"
            fill="#edeeef"
            fontSize="17.5"
            fontFamily="Arial-BoldMT, Arial"
            fontWeight="700"
          >
            <tspan x="0" y="0">
              UPLOAD
            </tspan>
          </text>
        </g>
      </svg>
    </>
  );
}
