import { IConfig, ISttStatus } from '../../components/Main/ISettings';

export interface ISnack {
  open: boolean;
  message: string;
  variant: 'success' | 'error';
}

export enum UserRoleEnums {
  MODEL_UPDATER_READER = 'MODEL_UPDATER_READER',
  FILE_UPLOADER = 'FILE_UPLOADER',
  DICTIONARY_WRITER = 'DICTIONARY_WRITER',
  DICTIONARY_READER = 'DICTIONARY_READER',
  offline_access = 'offline_access',
  LIVE_STREAMER = 'LIVE_STREAMER',
  SUBSTITUTOR_READER = 'SUBSTITUTOR_READER',
  ADMIN = 'ADMIN',
  uma_authorization = 'uma_authorization',
  MODEL_UPDATER_WRITER = 'MODEL_UPDATER_WRITER',
  EDITOR_USER = 'EDITOR_USER',
}

export interface IUser {
  isAuthenticated: boolean;
  username: string;
  accessToken?: string;
  refreshToken?: string;
  userRoles: UserRoleEnums[];
  isEditorUser?: boolean;
  isAdmin?: boolean;
}

export interface IRefectTokens {
  refetchCount: number;
  refetchTime: number | null;
}

export interface IStore {
  doDictation: boolean;
  refetchTokens: {
    refetchCount: 0;
    refetchTime: null;
  };
  makeBlocks: boolean;
  saveToDisk: boolean;
  user: IUser | null;
  config: IConfig | null;
  snack: ISnack;
  delay: number;
  uploadInLiveMode: boolean;
  audioChunkSize: number;
  sttStatus: ISttStatus | null;
}
