import React from "react";

export function DownArrow(props: any) {
  return (
    <>
      <svg
        id="Capa_1"
        enableBackground="new 0 0 551.13 551.13"
        height={props.height}
        viewBox="0 0 551.13 551.13"
        width={props.width}
        xmlns="http://www.w3.org/2000/svg"
        fill="#ffffff"
      >
        <path d="m275.565 361.679-223.897-223.896h-51.668l275.565 275.565 275.565-275.565h-51.668z" />
      </svg>
    </>
  );
}
