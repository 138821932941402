import React from "react";

export function FullScreenIcon(props: any) {
  const color = props.hover ? "#2B8DFF" : "rgb(237, 238, 239)";
  return (
    <>
      <svg
        version="1.1"
        id="Capa_1"
        x="0px"
        y="0px"
        width="38px"
        height="38px"
        viewBox="0 0 357 357"
        fill={color}
      >
        <g>
          <g id="fullscreen">
            <path
              d="M51,229.5H0V357h127.5v-51H51V229.5z M0,127.5h51V51h76.5V0H0V127.5z M306,306h-76.5v51H357V229.5h-51V306z M229.5,0v51
			H306v76.5h51V0H229.5z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </>
  );
}
