import React from "react";

export function StreamIcon(props: any) {
    const bgColor = props.hover ? "#2B8DFF" : "rgba(37,37,37,0.5)";
    const channelNameColor = props.hoverActive
      ? "rgb(237, 238, 239)"
      : "rgba(37, 37, 37, 0.5)";
    return (
        <svg id="stream" xmlns="http://www.w3.org/2000/svg" width="110.9" height="114.2" viewBox="0 0 110.9 114.2">
            <g id="g31701" transform="translate(0.091 -16.745)">
                <path id="path4827" d="M12724.986-15171.4h13.037v-13.084h-13.037Z" transform="translate(-12630.865 15302.323)" fill={channelNameColor} />
                <path id="path4813" d="M12573.531-15361.229h-80.937v-114.2h80.938Z" transform="translate(-12492.594 15492.174)" fill={bgColor} />
                <path id="download-network-outline" d="M34.128,45.014a2.471,2.471,0,0,0-2.471-2.471H29.186V37.6h9.886a4.943,4.943,0,0,0,4.943-4.943V7.943A4.943,4.943,0,0,0,39.071,3H14.357A4.943,4.943,0,0,0,9.414,7.943V32.657A4.943,4.943,0,0,0,14.357,37.6h9.886v4.943H21.771A2.471,2.471,0,0,0,19.3,45.014H2v4.943H19.3a2.471,2.471,0,0,0,2.471,2.471h9.886a2.471,2.471,0,0,0,2.471-2.471h17.3V45.014h-17.3M14.357,32.657V7.943H39.071V32.657H14.357m12.357-2.471L36.6,20.3H29.186V10.414H24.243V20.3H16.828Z" transform="translate(29.509 74.247)" fill="#edeeef" />
                <text id="STREAM-2" data-name="STREAM" transform="translate(106.809 111.747) rotate(-90)" fill={channelNameColor} font-size="17.5" font-family="HelveticaNeue-Bold, Helvetica Neue" font-weight="700"><tspan x="0" y="0">STREAM</tspan></text>
                <line id="Line_31" data-name="Line 31" x2="32" transform="translate(-0.091 121.747)" fill="none" stroke="#edeeef" stroke-width="5" stroke-dasharray="4" />
            </g>
        </svg>

    );
}