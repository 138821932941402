import {useState} from "react";
import {ITranscriptWord, MetadataSources} from "../components/Main/IMain";
import {useSelector} from "react-redux";
import {IStore} from "../redux/store/IStore";

const MICROPHONE_ICON = 0x1F399;
const SPEAKER_ICON = 0x1F5E3;
const MOUTH_ICON = 0x1F444;

export interface IUseSpeakerChange {
  applySpeakerChanges: (trContent: ITranscriptWord[]) => ITranscriptWord[];
}

export default (): IUseSpeakerChange => {
  const [ lastSpeakerPrevTr, setLastSpeakerPrevTr ] = useState<string>();

  const config = useSelector((state: IStore) => state.config);

  const applySpeakerChanges = (trContent: ITranscriptWord[]) => {

    // If speaker detect option is disabled just return the transcript
    if(config !== null && !config.transcriptionDoDiarization) return trContent;

    let trContentCopy = JSON.parse(JSON.stringify(trContent)) as ITranscriptWord[];

    let lastSpeaker = lastSpeakerPrevTr;
    for(let i = 0; i < trContentCopy.length; i++) {

      if(
        lastSpeaker !== undefined &&
        trContentCopy[i].speakerCode !== undefined &&
        trContentCopy[i].speakerCode !== lastSpeaker
      ) {
        trContentCopy.splice(i, 0, {
          startTime: -2,
          endTime: -1,
          text: String.fromCodePoint(SPEAKER_ICON),
          confidence: 1,
          spaceBefore: true,
          metadata: {
            postCapitalized: false,
            source: MetadataSources.STT
          }
        });
        i++;
      }

      // Update last speaker
      if(trContentCopy[i].speakerCode !== undefined) {
        lastSpeaker = trContentCopy[i].speakerCode;
      }
    }

    setLastSpeakerPrevTr(lastSpeaker);

    return trContentCopy;
  };

  return {
    applySpeakerChanges
  }
}