import React from "react";

export function TvSlo2(props: any) {
  const bgColor = props.hover ? "#2B8DFF" : "rgba(37,37,37,0.5)";
  const channelNameColor = props.hoverActive
    ? "rgb(237, 238, 239)"
    : "rgba(37, 37, 37, 0.5)";
  const desiredHeight = props.height ? props.height : "114px";
  const desiredWidth = props.width ? props.width : "107.04px";
  return (
    <>
      <svg
        id="TV_SLO_2"
        data-name="TV SLO 2"
        xmlns="http://www.w3.org/2000/svg"
        width={desiredWidth}
        height={desiredHeight}
        viewBox="0 0 107.356 113.824"
      >
        <g id="g31578" transform="translate(0 -17.307)">
          <path
            id="path2333"
            d="M12568.445-19305.324h-81.053v-113.824h81.053Z"
            transform="translate(-12487.393 19436.455)"
            fill={bgColor}
          />
          <path
            id="path2345"
            d="M-68.032-118.685h-46.452V-128.95l30.929-37.6c2.849-3.451,4.288-7.035,4.288-10.721a12.458,12.458,0,0,0-3.113-8.856q-3.106-3.326-8.635-3.334a12.207,12.207,0,0,0-8.459,3.04c-2.262,2.041-3.392,5.155-3.392,9.37h-11.514q0-10.155,6.55-16.419t16.816-6.256q10.4,0,16.816,6.139a20.574,20.574,0,0,1,6.168,12.38v7.769a17.315,17.315,0,0,1-1.483,4.92,47.434,47.434,0,0,1-5.8,8.738l-25.172,30.826h32.456Z"
            transform="translate(149.033 249.815)"
            fill="rgb(237, 238, 239)"
          />
          <path
            id="path2347"
            d="M12719.727-19115.289h13.087v-13.148h-13.087Z"
            transform="translate(-12625.457 19246.395)"
            fill={channelNameColor}
          />
          <path
            id="path2351"
            d="M0,0V9.179H2.218V5.83h10.53v-2.5H2.218V0Z"
            transform="translate(94.306 101.34)"
            fill={channelNameColor}
          />
          <path
            id="path2355"
            d="M0-3.807l8.3,2.57L0,1.318v2.6L12.747-.3v-1.88L0-6.406Z"
            transform="translate(94.306 95.657)"
            fill={channelNameColor}
          />
          <path
            id="path2367"
            d="M-13.314,0a3.371,3.371,0,0,1,2.834,1.336A5.407,5.407,0,0,1-9.451,4.744a8.207,8.207,0,0,1-.382,2.629A5.227,5.227,0,0,1-11.14,9.4l-1.6-1.615A4.147,4.147,0,0,0-11.669,4.7c0-1.513-.529-2.262-1.571-2.262a1.4,1.4,0,0,0-1.072.4,2.192,2.192,0,0,0-.455,1.131l-.206,1.542a4.459,4.459,0,0,1-1.057,2.482,3.421,3.421,0,0,1-2.511.911,3.531,3.531,0,0,1-2.79-1.2A4.654,4.654,0,0,1-22.4,4.45,5.731,5.731,0,0,1-20.921.323l1.557,1.586a3.668,3.668,0,0,0-.866,2.614,2.146,2.146,0,0,0,.455,1.469,1.461,1.461,0,0,0,1.146.5,1.174,1.174,0,0,0,.911-.367,2.1,2.1,0,0,0,.5-1.19L-17,3.422A4.608,4.608,0,0,1-16.016.984,3.529,3.529,0,0,1-13.314,0"
            transform="translate(116.604 74.105)"
            fill={channelNameColor}
          />
          <path
            id="path2371"
            d="M-15.4,0V5.8H-25.915V8.283h12.733V0Z"
            transform="translate(120.227 63.46)"
            fill={channelNameColor}
          />
          <path
            id="path2383"
            d="M-7.141-.022c.338-.015.866-.015,1.586-.015s1.263,0,1.6.015S-3.205.037-2.72.1a3.654,3.654,0,0,1,1.263.4,3.574,3.574,0,0,1,.984.749,4.617,4.617,0,0,1,1.4,3.437,4.66,4.66,0,0,1-1.4,3.466,4.023,4.023,0,0,1-.969.734A3.983,3.983,0,0,1-2.72,9.26a9.4,9.4,0,0,1-1.234.117c-.308.015-.837.029-1.6.029s-1.278-.015-1.586-.029A9.4,9.4,0,0,1-8.375,9.26a3.983,3.983,0,0,1-1.278-.382,4.023,4.023,0,0,1-.969-.734,4.66,4.66,0,0,1-1.4-3.466,4.617,4.617,0,0,1,1.4-3.437A3.574,3.574,0,0,1-9.638.493,3.672,3.672,0,0,1-8.389.1c.5-.059.911-.1,1.248-.117m4.068,2.6A17.988,17.988,0,0,0-5.555,2.46a17.883,17.883,0,0,0-2.467.117,2.251,2.251,0,0,0-1.1.5,2.023,2.023,0,0,0-.676,1.6,2.013,2.013,0,0,0,.676,1.615,2.078,2.078,0,0,0,1.087.5,17.988,17.988,0,0,0,2.482.117,18.246,18.246,0,0,0,2.5-.117,2.078,2.078,0,0,0,1.087-.5A2.013,2.013,0,0,0-1.3,4.678a2.023,2.023,0,0,0-.676-1.6,2.251,2.251,0,0,0-1.1-.5"
            transform="translate(106.227 52.925)"
            fill={channelNameColor}
          />
        </g>
      </svg>
    </>
  );
}
